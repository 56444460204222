import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import Pageloader from '../loader/Cms1';

export default function Faqs() {
    const [faqscms, setFaqscms] = useState('');
    const [faqs, setFaqs] = useState([]);
    const [pageloader, setPageloader] = useState(false);

    useEffect(() => {
        setPageloader(true);
        const fetchFaq = async () => {
            await axios.get(process.env.REACT_APP_API + 'faqs')
                .then((response) => {
                    setFaqs(response.data.result.data);
                    setPageloader(false);
                })
        }
        fetchFaq();
    }, [setFaqs]);


    useEffect(() => {
        const fetchFaqCms = async () => {
            await axios.get(process.env.REACT_APP_API + 'getcmsdata/11')
                .then((response) => {
                    setFaqscms(response.data.result);
                })
        }
        fetchFaqCms();
    }, [setFaqscms]);



    return (
        <>
            <Helmet>
                <title>FAQs : Intelligence Security of India</title>
                <meta name="description" content="Welcome to Intelligence Security of India" />
                <meta name="keywords" content="Welcome to Intelligence Security of India" />
            </Helmet>
            <main>
                <section className="InnerHead bg-black">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 287.81"><path fill="#f36d22" d="M1600,41.81s-217-137-550,85C784.59,303.75,525,275.58,450,270.31c-72.82-5.12-197.95-53.5-312-53.5-42.61-.32-120.09,22.27-138,32,.06,0,0,35,0,35H1600Z" /><path fill="#fff" d="M1600,57.81s-267-99-540,66-470.52,158-575,158-281-51-397-51c-42.61,0-70.09,7.27-88,17,.06,0,0,40,0,40H1600Z" /></svg>
                    <picture>
                        <source srcSet={require("../img/banner1.webp")} type="image/webp" />
                        <img src={require("../img/banner1.jpg")} className="imgbg" alt="Gallery" width="1400" height="520" />
                    </picture>
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col-md-8">
                                <h1 className="fw-normal text-white">FAQs</h1>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/"><img src={require("../img/home.svg").default} width="14" height="14" alt="Home" /></Link></li>
                                    <li className="breadcrumb-item"><a href='/#' aria-current="page">FAQs</a></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    pageloader ?
                        <Pageloader />
                        :
                        <section>
                            <div className="container">
                                <div className="row justify-content-center">
                                    {
                                        faqscms ?
                                            <div className="col-lg-10 col-md-11 text-center">
                                                <span className="SubTitle thm1">{faqscms.title}</span>
                                                <h2 className="Heading">{faqscms.heading}</h2>
                                                <div dangerouslySetInnerHTML={{ __html: faqscms.description }} />
                                            </div>
                                            :
                                            ''
                                    }

                                    {
                                        faqs.length > 0 ?


                                            <div className="col-lg-10 col-md-11">
                                                <div className="accordion accordion-flush Faqs" id="Faqs">
                                                    {
                                                        faqs.map((faq, f) =>
                                                            <div className="accordion-item" key={f}>
                                                                <div className="accordion-header" id="Pay1">
                                                                    <button className={
                                                                        f === 0 ? "accordion-button" : "accordion-button collapsed"
                                                                    } type="button" data-bs-toggle="collapse" data-bs-target={"#Faqs" + f} aria-expanded="true" aria-controls="Faqs1">{faq.title}</button>
                                                                </div>
                                                                <div id={"Faqs" + f} className={
                                                                    f === 0 ? "accordion-collapse collapse show" : "accordion-collapse collapse"}
                                                                    aria-labelledby="Pay1" data-bs-parent="#Faqs">
                                                                    <div className="accordion-body" dangerouslySetInnerHTML={{ __html: faq.description }} />
                                                                </div>
                                                            </div>
                                                        )
                                                    }

                                                </div>
                                            </div>

                                            : ''}
                                </div>
                            </div>
                        </section>
                }
            </main>
        </>
    )
}
