import React from "react"
import ContentLoader from "react-content-loader"

const MyLoader = (props) => (
    <div className='container text-center' style={{opacity:'.5'}}>
        <ContentLoader viewBox="0 0 600 100" backgroundColor="#ddd" foregroundColor="#a6b5ce">
            <rect x="200" y="50" rx="2" ry="2" width="200" height="21" /> 
            <rect x="250" y="30" rx="2" ry="2" width="100" height="11" />
        </ContentLoader>
        <ContentLoader viewBox="0 0 400 130" backgroundColor="#ddd" foregroundColor="#a6b5ce">
            <rect x="50" y="10" rx="4" ry="4" width="300" height="30" />
            <rect x="50" y="50" rx="4" ry="4" width="300" height="30" />
            <rect x="50" y="90" rx="4" ry="4" width="300" height="30" />
        </ContentLoader>
    </div>
)

export default MyLoader