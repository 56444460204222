import axios from 'axios';
import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import helpers from '../Helper';
import ContactLoader from '../loader/Contact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function Contact() {
    const [contactcms, setContactcms] = useState('');
    const [contacts, setContacts] = useState([]);
    const [pageloader, setPageloader] = useState(false);

    const [btnloader, setBtnloader] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [companyname, setCompanyname] = useState("");
    const [error, setError] = useState({ name: "", email: "", phone: "", message: "", companyname: "" });


    useEffect(() => {
        setPageloader(true);
        const fetchContact = async () => {
            await axios.get(process.env.REACT_APP_API + 'contacts')
                .then((response) => {
                    setContacts(response.data.result.data);
                    setPageloader(false);
                })
        }
        fetchContact();
    }, [setContacts]);

    useEffect(() => {
        const fetchCms2 = async () => {
            await axios.get(process.env.REACT_APP_API + 'getcmsdata/4')
                .then((response) => {
                    setContactcms(response.data.result);
                })
        }
        fetchCms2();
    }, [setContactcms]);

    async function ContactForm(e) {
        e.preventDefault();
        setBtnloader(true);
        setError({ name: "", email: "", phone: "", message: "", companyname: "" });
        let callback = { name: name, email: email, phone: phone, message: message, companyname: companyname }
        await axios.post(process.env.REACT_APP_API + 'contactform', callback)
            .then((response) => {
                toast.success(response.data.message);
                setBtnloader(false);
                setName("");
                setEmail("");
                setPhone("");
                setCompanyname("");
                setMessage("");
            })
            .catch((errors) => {
                if (errors.response !== undefined) {
                    let rterror = errors.response.data.errors;
                    setError({
                        name: rterror.name,
                        email: rterror.email,
                        phone: rterror.phone,
                        message: rterror.message,
                        companyname: rterror.companyname
                    });
                    setBtnloader(false);
                }
            });
    }

    return (
        <>
            <Helmet>
                <title>Contact Us : Intelligence Security of India</title>
                <meta name="description" content="Welcome to Intelligence Security of India" />
                <meta name="keywords" content="Welcome to Intelligence Security of India" />
            </Helmet>
            <main>
                <section className="InnerHead bg-black">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 287.81"><path fill="#f36d22" d="M1600,41.81s-217-137-550,85C784.59,303.75,525,275.58,450,270.31c-72.82-5.12-197.95-53.5-312-53.5-42.61-.32-120.09,22.27-138,32,.06,0,0,35,0,35H1600Z" /><path fill="#fff" d="M1600,57.81s-267-99-540,66-470.52,158-575,158-281-51-397-51c-42.61,0-70.09,7.27-88,17,.06,0,0,40,0,40H1600Z" /></svg>
                    <picture>
                        <source srcSet={require("../img/banner1.webp")} type="image/webp" />
                        <img src={require("../img/banner1.jpg")} className="imgbg" alt="Gallery" width="1400" height="520" />
                    </picture>
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col-md-8">
                                <h1 className="fw-normal text-white">Contact Us</h1>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/"><img src={require("../img/home.svg").default} width="14" height="14" alt="Home" /></Link></li>
                                    <li className="breadcrumb-item"><a href='/#' aria-current="page">Contact Us</a></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    pageloader ?
                        <ContactLoader />
                        :
                        <section className="Sec6 Contact">
                            <div className="container">
                                {
                                    contactcms ?
                                        <div className="row justify-content-center">
                                            <div className="col-lg-9 col-md-10 text-center">
                                                <span className="SubTitle thm1">{contactcms.title}</span>
                                                <h2 className="Heading">{contactcms.heading}</h2>
                                                <div dangerouslySetInnerHTML={{ __html: contactcms.description }} />
                                            </div>
                                        </div>
                                        :
                                        ''
                                }

                                <div className="row">
                                    <div className="col-lg-4 order-lg-last">
                                        {
                                            contactcms ?
                                                helpers.helpers1(contactcms.image) ?
                                                    <picture>
                                                        <img src={process.env.REACT_APP_IMAGE_FOLDER + 'cms/' + contactcms.image} alt={contactcms.title} width="750" height="400" className="w-100 bgimgS" />
                                                    </picture>
                                                    :
                                                    <picture>
                                                        <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'cms/webp/' + contactcms.image + '.webp'} type="image/webp" />
                                                        <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'cms/avif/' + contactcms.image + '.avif'} type="image/webp" />
                                                        <img src={process.env.REACT_APP_IMAGE_FOLDER + 'cms/jpg/' + contactcms.image + '.jpg'} alt={contactcms.title} width="750" height="400" className="w-100 bgimgS" />
                                                    </picture>
                                                :
                                                <img src={require("../img/contact.svg").default} alt="contact" className="w-100" width="750" height="400" />

                                        }
                                        {
                                            contacts.length > 0 && contacts.map((list, ls) =>
                                                <div className="bgthm ConInfo mb-2" key={ls}>
                                                    <ul>
                                                        <li><img src={require("../img/location-w.svg").default} alt="location" /> <span><strong>{list.title}</strong><br />{list.address}</span></li>
                                                        {
                                                            list.phone ?
                                                                <li><img src={require("../img/call-w.svg").default} alt="call" /> <a href={'tel:' + list.phone}>{list.phone}</a></li>
                                                                :
                                                                ''
                                                        }
                                                        {
                                                            list.email ?
                                                                <li><img src={require("../img/mail-w.svg").default} alt="mail" /> <a href={'mailto:' + list.email}>{list.email}</a></li>
                                                                :
                                                                ''
                                                        }
                                                    </ul>
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="col-lg-8">
                                        <form id="formsubmit" onSubmit={ContactForm}>
                                            <h3 className="h4 m-0">Get in Touch</h3><hr />
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="Name" className="form-label m-0">Your Name</label>
                                                    <input className="form-control" id="Name" value={name} onChange={(e) => setName(e.target.value)} type="text" />
                                                    {error.name ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{error.name}</span> : ''}
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="Name" className="form-label m-0">Your Company Name</label>
                                                    <input className="form-control" id="Name" value={companyname} onChange={(e) => setCompanyname(e.target.value)} type="text" />
                                                    {error.companyname ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{error.companyname}</span> : ''}
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="Email" className="form-label m-0">Your Email ID</label>
                                                    <input className="form-control" id="Email" value={email} onChange={(e) => setEmail(e.target.value)} type="email" />
                                                    {error.email ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{error.email}</span> : ''}
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="Contact" className="form-label m-0">Your Contact No.</label>
                                                    <input className="form-control" id="Contact" value={phone} onChange={(e) => setPhone(e.target.value)} type="number" maxLength="10" />
                                                    {error.phone ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{error.phone}</span> : ''}
                                                </div>
                                                <div className="col-12 mb-3">
                                                    <label htmlFor="Message" className="form-label m-0">Message</label>
                                                    <textarea className="form-control" id="Message" value={message} onChange={(e) => setMessage(e.target.value)} ></textarea>
                                                    {error.message ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{error.message}</span> : ''}
                                                </div>
                                            </div>
                                            {
                                                btnloader ?
                                                    <button type="button" className="btn btn-thm disabled"><FontAwesomeIcon icon={faSpinner} className="fa-spin me-1" /> Loading...</button>
                                                    :
                                                    <button type="submit" name="submit" className="btn btn-thm">Submit</button>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>
                }

                {
                    contactcms ?
                        <section className="map p-0 lh-1">
                            <div className='row'>
                                <div className='col-md-6' dangerouslySetInnerHTML={{ __html: contactcms.description_2 }} />
                                <div className='col-md-6'>
                                    {
                                        helpers.helpers1(contactcms.image_2) ?
                                            <picture>
                                                <img src={process.env.REACT_APP_IMAGE_FOLDER + 'cms/' + contactcms.image_2} alt={contactcms.title} width="750" height="400" className="w-100 bgimgS" />
                                            </picture>
                                            :
                                            <picture>
                                                <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'cms/webp/' + contactcms.image_2 + '.webp'} type="image/webp" />
                                                <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'cms/avif/' + contactcms.image_2 + '.avif'} type="image/webp" />
                                                <img src={process.env.REACT_APP_IMAGE_FOLDER + 'cms/jpg/' + contactcms.image_2 + '.jpg'} alt={contactcms.title} width="750" height="400" className="w-100 bgimgS" />
                                            </picture>
                                    }
                                </div>
                            </div>
                        </section>
                        :
                        ''
                }


            </main>
        </>
    )
}
