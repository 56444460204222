import React from "react"
import ContentLoader from "react-content-loader"

const MyLoader = (props) => (
  <div className="container">
    <div className='container text-center' style={{opacity:'.5'}}>
      <ContentLoader viewBox="0 0 600 100" backgroundColor="#ddd" foregroundColor="#a6b5ce">
        <rect x="200" y="50" rx="2" ry="2" width="200" height="21" /> 
        <rect x="250" y="30" rx="2" ry="2" width="100" height="11" />
        <rect x="100" y="80" rx="2" ry="2" width="400" height="6" />
      </ContentLoader>
      <ContentLoader viewBox="0 0 600 300" backgroundColor="#ddd" foregroundColor="#a6b5ce">
        <rect x="0" y="10" rx="2" ry="2" width="142" height="110" />
        <rect x="152" y="10" rx="2" ry="2" width="142" height="110" />
        <rect x="306" y="10" rx="2" ry="2" width="142" height="110" />
        <rect x="458" y="10" rx="2" ry="2" width="142" height="110" />
        <rect x="0" y="132" rx="2" ry="2" width="142" height="110" />
        <rect x="152" y="132" rx="2" ry="2" width="142" height="110" />
        <rect x="306" y="132" rx="2" ry="2" width="142" height="110" />
        <rect x="458" y="132" rx="2" ry="2" width="142" height="110" />
      </ContentLoader>
    </div>
  </div>
)

export default MyLoader