import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom';
import '../assets/career.min.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import Pageloader from '../loader/Career';
export default function Career() {
    const [pageloader, setPageloader] = useState(false);
    const [dataloader, setLoader] = useState(false);
    const [pagedata, setPagedata] = useState();
    const [careerlists, setcareerlists] = useState([]);
    const [career, setCareer] = useState([]);

    const [btnloader, setBtnloader] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [experience, setExperience] = useState("");
    const [resume, setResume] = useState("");
    const [job, setJob] = useState("");
    const [error, setError] = useState({ name: "", email: "", phone: "", experience: "", resume: "" });

    useEffect(() => {
        setPageloader(true);
        async function fetchData() {
            await axios.get(process.env.REACT_APP_API + 'getcmsdata/10')
                .then((response) => {
                    setPagedata(response.data.result);
                    setPageloader(false);
                });
        }
        fetchData();
    }, [setPagedata]);

    useEffect(() => {
        async function getcareerdata() {
            await axios.get(process.env.REACT_APP_API + 'careers').then((response) => {
                setcareerlists(response.data.result.data);

            });
        }
        getcareerdata();
    }, [setcareerlists]);

    async function JobSubmit(e) {
        setError({ name: "", email: "", phone: "", experience: "", resume: "" });
        setBtnloader(true);
        e.preventDefault();
        let callback = { name: name, email: email, phone: phone, experience: experience, resume: resume, job_id: job }
        let config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        }
        await axios.post(process.env.REACT_APP_API + 'applyjob/', callback, config)
            .then((responose) => {
                toast.success(responose.data.message);
                setBtnloader(false);
                setName("");
                setEmail("");
                setPhone("");
                setExperience("");
                setResume("");
                $('#CareerForm').modal('hide');
                $('.Carrefrm').trigger('reset');
                setError({ name: "", email: "", phone: "", experience: "", resume: "" });

            })
            .catch((errors) => {
                if (errors.response !== undefined) {
                    let rterror = errors.response.data.errors;
                    setError({
                        name: rterror.name,
                        email: rterror.email,
                        phone: rterror.phone,
                        experience: rterror.experience,
                        resume: rterror.resume
                    });
                    setBtnloader(false);
                }

            })
    }

    async function getcareerdtldata(id) {
        setLoader(true);
        await axios.get(process.env.REACT_APP_API + 'careers/' + id).then((response) => {
            setCareer(response.data.result);
            setLoader(false);
        });
    }

    return (
        <>
            <Helmet>
                <title>Careers : Intelligence Security of India</title>
                <meta name="description" content="Welcome to Intelligence Security of India" />
                <meta name="keywords" content="Welcome to Intelligence Security of India" />
            </Helmet>
            <main>
                <section className="InnerHead bg-black">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 287.81"><path fill="#f36d22" d="M1600,41.81s-217-137-550,85C784.59,303.75,525,275.58,450,270.31c-72.82-5.12-197.95-53.5-312-53.5-42.61-.32-120.09,22.27-138,32,.06,0,0,35,0,35H1600Z" /><path fill="#fff" d="M1600,57.81s-267-99-540,66-470.52,158-575,158-281-51-397-51c-42.61,0-70.09,7.27-88,17,.06,0,0,40,0,40H1600Z" /></svg>
                    <picture>
                        <source srcSet={require("../img/banner1.webp")} type="image/webp" />
                        <img src={require("../img/banner1.jpg")} className="imgbg" alt="Gallery" width="1400" height="520" />
                    </picture>
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col-md-8">
                                <h1 className="fw-normal text-white">Careers</h1>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/"><img src={require("../img/home.svg").default} width="14" height="14" alt="Home" /></Link></li>
                                    <li className="breadcrumb-item"><a href="/#" aria-current="page">Careers</a></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    pageloader ?
                        <Pageloader />
                        :
                        <section>

                            <div className="container">
                                {
                                    pagedata ?
                                        <div className="row mb-4 justify-content-center">
                                            <div className="col-lg-9 col-md-10 text-center">
                                                <span className="SubTitle thm1">{pagedata.title}</span>
                                                <h2 className="Heading">{pagedata.heading}</h2>
                                                <div dangerouslySetInnerHTML={{ __html: pagedata.description }} />
                                            </div>
                                        </div>
                                        :
                                        ''
                                }

                                {
                                    careerlists.length > 0 ?
                                        <div className="row justify-content-center">
                                            <div className="col-lg-9 col-md-11 mb-4">
                                                {
                                                    careerlists.map((lists, a) =>
                                                        <div key={a} className="card card-body Openings mb-4 px-md-4 py-md-4 justify-content-between align-items-center flex-md-row">
                                                            <div>
                                                                <h3 className="h5 m-0 fw-bold">{lists.title}</h3>
                                                                <p className="thm1 m-0">{lists.department}</p>
                                                            </div>
                                                            <span>
                                                                <a data-bs-toggle="modal" onClick={() => getcareerdtldata(lists.id)} href="#Detail" className="btn btn-thm1 mt-md-0 me-3">Description</a>
                                                                <a data-bs-toggle="modal" onClick={() => setJob(lists.id)} href="#CareerForm" className="btn btn-thm mt-md-0">Apply Now</a>
                                                            </span>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        :
                                        ''
                                }


                            </div>
                        </section>
                }
            </main>
            <div className="modal fade PopupM" id="CareerForm" tabIndex="-1" aria-labelledby="CareerFormLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <form onSubmit={JobSubmit} className="modal-content Carrefrm">
                        <div className="modal-header border-0 d-none">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body pt-5 px-md-5">
                            <h3 className="fw-bold h4 thm">Apply Now</h3>
                            <p>Fill out the form below and apply to be a part of the Intelligence team.</p>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="name" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />
                                        <label htmlFor="name" className="form-label">Name<span className="text-danger">*</span></label>
                                        {error.name ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{error.name}</span> : ''}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-floating mb-3">
                                        <input type="number" className="form-control" id="experience" value={experience} onChange={(e) => setExperience(e.target.value)} placeholder="Experience (in years)" />
                                        <label htmlFor="experience" className="form-label">Experience (in years)<span className="text-danger">*</span></label>
                                        {error.experience ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{error.experience}</span> : ''}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email Address" />
                                        <label htmlFor="email" className="form-label">Email Address<span className="text-danger">*</span></label>
                                        {error.email ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{error.email}</span> : ''}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-floating mb-3">
                                        <input type="number" className="form-control" id="mobile" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Contact Number" />
                                        <label htmlFor="mobile" className="form-label">Contact Number<span className="text-danger">*</span></label>
                                        {error.phone ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{error.phone}</span> : ''}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="formFileLg" className="form-label">Upload your resume<span className="text-danger">*</span></label>
                                    <div className="form-floating mb-3">
                                        <input className="form-control" id="formFileLg" onChange={(e) => setResume(e.target.files[0])} type="file" />
                                    </div>
                                    {error.resume ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{error.resume}</span> : ''}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer pt-4 border-top pb-5 d-block text-center">
                            <p>Please take a minute to review if the above details are correct.</p>
                            <button type="button" className="btn btn-thm1" data-bs-dismiss="modal">Close</button>
                            {
                                btnloader ?
                                    <button type="button" className="btn btn-thm disabled"><FontAwesomeIcon icon={faSpinner} className="fa-spin me-1" /> Loading...</button>
                                    :
                                    <button className="btn btn-thm">Submit Application</button>
                            }
                        </div>
                    </form>
                </div>
            </div>
            <div className="modal fade" id="Detail" tabIndex="-1" aria-labelledby="CareerFormLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <form action="#" className="modal-content">
                        <div className="modal-header border-0 d-none">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body CmsPage px-md-4">
                            {
                                dataloader ?
                                    <div className='my-5'>
                                        <Pageloader />
                                    </div>
                                    :
                                    <>
                                        <h2>{career.title ?? ''}</h2>
                                        <p>{career.department ?? ''}</p>
                                        <div dangerouslySetInnerHTML={{ __html: career.description ?? '' }} />
                                    </>
                            }

                        </div>
                        <div className="modal-footer border-top d-block text-center">
                            <button type="button" className="btn btn-thm1 btn-sm mt-0" data-bs-dismiss="modal">Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
