import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import '../assets/home.min.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import helpers from '../Helper';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import Corevalue from './layout/Corevalue';
import GerAQuotes from './layout/GetAQuotes';

import SliderLoader from '../loader/Slider';
import AboutLoader from '../loader/About';
import ServiceLoader from '../loader/Service';
import BlogLoader from '../loader/Blog';
import ContactLoader from '../loader/Contact';
import { Helmet } from 'react-helmet-async';

export default function Home() {
    const [sliders, setSlider] = useState([]);
    const [aboutdata, setAboutData] = useState('');

    const [whatwedo, setWhatwedo] = useState('');
    const [servicecms, setServicecms] = useState('');
    const [services, setServices] = useState([]);
    const [blogcms, setBlogcms] = useState('');
    const [blogs, setBlogs] = useState([]);
    const [faqscms, setFaqscms] = useState('');
    const [faqs, setFaqs] = useState([]);
    const [testimonialcms, setTestimonialscms] = useState('');
    const [testimonials, setTestimonials] = useState([]);
    const [contactcms, setContactcms] = useState('');
    const [contacts, setContacts] = useState([]);
    const [counters, setCounters] = useState([]);
    const [clients, setClient] = useState([]);

    const [slideloader, setSlideloader] = useState(true);
    const [aboutloader, setAboutloader] = useState(false);
    const [serviceloader, setServiceloader] = useState(false);
    const [blogloader, setBlogloader] = useState(false);
    const [contactloader, setContactloader] = useState(false);

    const [btnloader, setBtnloader] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [companyname, setCompanyname] = useState("");
    const [error, setError] = useState({ name: "", email: "", phone: "", message: "", companyname: "" });

    
    const fetchBannerApi = async () => {
        const BannerApi = process.env.REACT_APP_API + 'banners';
        axios.get(BannerApi)
        .then((res)=>{
            setSlider(res.data.result); 
            setSlideloader(false); 
        })
    }

    const fetchClientApi = async () => {
        const ClientApi = process.env.REACT_APP_API + 'clients';
        axios.get(ClientApi)
        .then((res)=>{
            setClient(res.data.result.data);
        })
    }

    const fetchAboutApi = async () => {
        const AboutApi = process.env.REACT_APP_API + 'getcmsdata/13';
        axios.get(AboutApi)
        .then((res)=>{
            setAboutData(res.data.result);
            setAboutloader(false);
        })
    }

    const fetchWhatwedoApi = async () => {
        const WhatwedoApi = process.env.REACT_APP_API + 'getcmsdata/15';
        axios.get(WhatwedoApi)
        .then((res)=>{
            setWhatwedo(res.data.result);
        })
    }

    const fetchServiceApi = async () => {
        const ServiceApi = process.env.REACT_APP_API + 'services?type=home';
        axios.get(ServiceApi)
        .then((res)=>{
            setServiceloader(false);
            setServices(res.data.result.data);
        })
    }

    const fetchServiceHeadingApi = async () => {
        const ServiceApi = process.env.REACT_APP_API + 'getcmsdata/14';
        axios.get(ServiceApi)
        .then((res)=>{
            setServicecms(res.data.result);
        })
    }

    useEffect(() => {
        fetchBannerApi();
        fetchClientApi();
        fetchAboutApi();
        fetchWhatwedoApi();
        fetchServiceApi();

        /*** Heading Api***/
        
        fetchServiceHeadingApi();
    }, []);


    useEffect(() => {
        const fetchCounters = async () => {
            await axios.get(process.env.REACT_APP_API + 'counters')
                .then((response) => {
                    setCounters(response.data.result.data);

                    setTimeout(() => {
                        function numberWithCommas(n) {
                            var parts = n.toString().split(".");
                            return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        }
                        // var oTop = $('.counter').offset().top - window.innerHeight;
                        let a = 0;
                        if (a === 0) {
                            $('.counter-value').each(function () {
                                var $this = $(this), countTo = $this.attr('data-count');
                                $({
                                    countNum: $this.text()
                                }).animate({
                                    countNum: countTo
                                }, {
                                    duration: 5000,
                                    easing: 'swing',
                                    step: function () {
                                        $this.text(numberWithCommas(this.countNum));
                                    },
                                    complete: function () {
                                        $this.text(numberWithCommas(this.countNum));
                                        //alert('finished');
                                    }
                                });
                            }); a = 1;
                        }
                    }, 1000);
                })
        }
        fetchCounters();
    }, [setCounters]);

    useEffect(() => {
        setBlogloader(true);
        const fetchBlog = async () => {
            await axios.get(process.env.REACT_APP_API + 'blogs?type=topblogs')
                .then((response) => {
                    setBlogloader(false);
                    setBlogs(response.data.result.data);
                })
        }
        fetchBlog();

        const fetchCms2 = async () => {
            await axios.get(process.env.REACT_APP_API + 'getcmsdata/18')
                .then((response) => {
                    setBlogcms(response.data.result);
                })
        }
        fetchCms2();

    }, [setBlogs, setBlogcms]);

    useEffect(() => {
        const fetchFaq = async () => {
            await axios.get(process.env.REACT_APP_API + 'faqs?type=home')
                .then((response) => {
                    setFaqs(response.data.result.data);
                })
        }
        fetchFaq();

        const fetchCms2 = async () => {
            await axios.get(process.env.REACT_APP_API + 'getcmsdata/16')
                .then((response) => {
                    setFaqscms(response.data.result);
                })
        }
        fetchCms2();

    }, [setFaqs, setFaqscms]);

    useEffect(() => {
        const fetchTestimonial = async () => {
            await axios.get(process.env.REACT_APP_API + 'testimonials')
                .then((response) => {
                    setTestimonials(response.data.result.data);
                })
        }
        fetchTestimonial();

        const fetchCms2 = async () => {
            await axios.get(process.env.REACT_APP_API + 'getcmsdata/17')
                .then((response) => {
                    setTestimonialscms(response.data.result);
                })
        }
        fetchCms2();

    }, [setTestimonials, setTestimonialscms]);

    useEffect(() => {
        setContactloader(true);
        const fetchContact = async () => {
            await axios.get(process.env.REACT_APP_API + 'contacts?type=home')
                .then((response) => {
                    setContactloader(false);
                    setContacts(response.data.result.data);
                })
        }
        fetchContact();

        const fetchCms2 = async () => {
            await axios.get(process.env.REACT_APP_API + 'getcmsdata/19')
                .then((response) => {
                    setContactcms(response.data.result);
                })
        }
        fetchCms2();

    }, [setContacts, setContactcms]);

    async function ContactForm(e) {
        e.preventDefault();
        setBtnloader(true);
        setError({ name: "", email: "", phone: "", message: "", companyname: "" });
        let callback = { name: name, email: email, phone: phone, message: message, companyname: companyname }
        await axios.post(process.env.REACT_APP_API + 'contactform', callback)
            .then((response) => {
                toast.success(response.data.message);
                setBtnloader(false);
                setName("");
                setEmail("");
                setPhone("");
                setCompanyname("");
                setMessage("");
            })
            .catch((errors) => {
                if (errors.response !== undefined) {
                    let rterror = errors.response.data.errors;
                    setError({
                        name: rterror.name,
                        email: rterror.email,
                        phone: rterror.phone,
                        message: rterror.message,
                        companyname: rterror.companyname
                    });
                    setBtnloader(false);
                }
            });
    }

    return (
        <>
            <Helmet>
                <title>Home : Intelligence Security of India</title>
                <meta name="description" content="Welcome to Intelligence Security of India" />
                <meta name="keywords" content="Welcome to Intelligence Security of India" />
            </Helmet>
            <main>
                {
                    slideloader ?
                        <SliderLoader />
                        :
                        sliders.length > 0 ?
                            <section className="Slider bs pb-0">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 287.81"><path fill="#f36d22" d="M1600,41.81s-217-137-550,85C784.59,303.75,525,275.58,450,270.31c-72.82-5.12-197.95-53.5-312-53.5-42.61-.32-120.09,22.27-138,32,.06,0,0,35,0,35H1600Z" /><path fill="#fff" d="M1600,57.81s-267-99-540,66-470.52,158-575,158-281-51-397-51c-42.61,0-70.09,7.27-88,17,.06,0,0,40,0,40H1600Z" /></svg>
                                <Carousel fade nextIcon="" prevIcon="" interval={5000}>
                                        {
                                            sliders.map((slider, s) =>
                                                <Carousel.Item key={s} className={s === 0 ? 'carousel-item active' : 'carousel-item'}>
                                                    {
                                                        helpers.helpers1(slider.image) ?
                                                            <picture>
                                                                <img src={process.env.REACT_APP_IMAGE_FOLDER + 'banner/' + slider.image} alt={slider.image_alt} width="1400" height="520" />
                                                            </picture>
                                                            :
                                                            <picture>
                                                                <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'banner/webp/' + slider.image + '.webp'} type="image/webp" />
                                                                <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'banner/avif/' + slider.image + '.avif'} type="image/webp" />
                                                                <img src={process.env.REACT_APP_IMAGE_FOLDER + 'banner/jpg/' + slider.image + '.jpg'} alt={slider.image_alt} width="1400" height="520" />
                                                            </picture>
                                                    }

                                                    <Carousel.Caption className="carousel-caption d-flex">
                                                        <div className="row justify-content-center">
                                                            <div className="col-lg-7 col-md-8 text-center">
                                                                <h2 className="title h1">{slider.image_alt}</h2>
                                                                <div dangerouslySetInnerHTML={{ __html: slider.description }} />
                                                                {
                                                                    slider.link !== '' && slider.link !== '#' ?
                                                                        <a href={slider.link} className="btn btn-thm">View Detail</a>
                                                                        :
                                                                        ''
                                                                }

                                                            </div>
                                                        </div>
                                                    </Carousel.Caption>
                                                </Carousel.Item>
                                            )
                                        }
                                </Carousel>
                            </section>
                            :
                            ''
                }

                {
                    aboutloader ?
                        <AboutLoader />
                        :
                        <section className="Sec1">
                            <div className="container">
                                <div className="row justify-content-center">
                                    {
                                        aboutdata ?
                                            <div className="col-lg-9 col-md-10 text-center">
                                                <span className="SubTitle thm1">{aboutdata.title}</span>
                                                <h2 className="Heading">{aboutdata.heading}</h2>
                                                <div dangerouslySetInnerHTML={{ __html: aboutdata.description }} />
                                                <Link to="/about" className="btn btn-thm">Read More</Link>
                                            </div>
                                            :
                                            ''
                                    }

                                    <Corevalue />
                                </div>
                            </div>
                        </section>
                }

                {
                    whatwedo ?
                        <section className="Sec2">
                            {
                                helpers.helpers1(whatwedo.image) ?
                                    <picture>
                                        <img src={process.env.REACT_APP_IMAGE_FOLDER + 'cms/' + whatwedo.image} alt={whatwedo.title} width="750" height="400" className="bgimgS" />
                                    </picture>
                                    :
                                    <picture>
                                        <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'cms/webp/' + whatwedo.image + '.webp'} type="image/webp" />
                                        <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'cms/avif/' + whatwedo.image + '.avif'} type="image/webp" />
                                        <img src={process.env.REACT_APP_IMAGE_FOLDER + 'cms/jpg/' + whatwedo.image + '.jpg'} alt={whatwedo.title} width="750" height="400" className="bgimgS" />
                                    </picture>
                            }
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 offset-lg-6">
                                        <span className="SubTitle thm1 d-none">{whatwedo.title}</span>
                                        <h2 className="Heading">{whatwedo.heading}</h2>
                                        <div dangerouslySetInnerHTML={{ __html: whatwedo.description }} />
                                    </div>
                                </div>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600.25 443.23"><path className="cls-1" d="M1226.25,22.23C906.62,133,798,241.73,729.25,305.23c-110.9,102.5-294.9,68.5-350,41s-227-81.5-312-62c-75,18.5-67,66-67,66v93h1600v-414S1381.58-31.59,1226.25,22.23Z" /></svg>
                        </section>
                        :
                        ''
                }

                {
                    serviceloader ?
                        <ServiceLoader />
                        :
                        <section className="Sec3 Services Home">
                            {
                                servicecms ?
                                    helpers.helpers1(servicecms.image) ?
                                        <picture>
                                            <img src={process.env.REACT_APP_IMAGE_FOLDER + 'cms/' + servicecms.image} alt={servicecms.title} width="750" height="400" className="bgimgS" />
                                        </picture>
                                        :
                                        <picture>
                                            <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'cms/webp/' + servicecms.image + '.webp'} type="image/webp" />
                                            <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'cms/avif/' + servicecms.image + '.avif'} type="image/webp" />
                                            <img src={process.env.REACT_APP_IMAGE_FOLDER + 'cms/jpg/' + servicecms.image + '.jpg'} alt={servicecms.title} width="750" height="400" className="bgimgS" />
                                        </picture>
                                    :
                                    ''
                            }
                            <div className="container">
                                {
                                    servicecms ?
                                        <div className="row mb-4 justify-content-center">
                                            <div className="col-lg-9 col-md-10 text-center">
                                                <span className="SubTitle thm1">{servicecms.title}</span>
                                                <h2 className="Heading">{servicecms.heading}</h2>
                                                <div dangerouslySetInnerHTML={{ __html: servicecms.description }} />
                                            </div>
                                        </div>
                                        :
                                        ''
                                }

                                {
                                    services.length > 0 ?

                                        <div className="row">
                                            <div className="col-lg-8">
                                                <div className="row text-center">
                                                    {
                                                        services.map((service, sr) =>
                                                            <div className="col-md-6 mb-4" key={sr}>
                                                                <Link to={"/service/" + service.alias} className="card">
                                                                    <div className="card-body">
                                                                        {
                                                                            helpers.helpers1(service.image) ?
                                                                                <picture>
                                                                                    <img src={process.env.REACT_APP_IMAGE_FOLDER + 'service/' + service.image} alt={service.title} width="750" height="400" />
                                                                                </picture>
                                                                                :
                                                                                <picture>
                                                                                    <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'service/webp/' + service.image + '.webp'} type="image/webp" />
                                                                                    <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'service/avif/' + service.image + '.avif'} type="image/webp" />
                                                                                    <img src={process.env.REACT_APP_IMAGE_FOLDER + 'service/jpg/' + service.image + '.jpg'} alt={service.title} width="750" height="400" />
                                                                                </picture>
                                                                        }
                                                                        <h3 className="h5">{service.title}</h3>
                                                                        <p dangerouslySetInnerHTML={{ __html: service.short_description }} />
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        :

                                        ''
                                }


                            </div>
                        </section>
                }

                <GerAQuotes />

                <section className="counter p-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <OwlCarousel items={5} id="counter" loop autoplay={true} nav={false} dots={false} responsive={{ 0: { items: 1 }, 350: { items: 2 }, 460: { items: 2 }, 600: { items: 3 }, 768: { items: 3 }, 992: { items: 4 }, 1200: { items: 4 }, 1600: { items: 5 } }}>
                                    {
                                        counters.map((counter, ct) =>
                                            <div className="item" key={ct}>
                                                <div>
                                                    <h3 className="thm1 h1 mb-1"><span className="counter-value" data-count={counter.counts}>0</span>
                                                        {counter.length === (ct + 1) ? <sup>+</sup> : ''}
                                                    </h3>
                                                    <h4 className="m-0 text-white fw-normal h5">{counter.title}</h4>
                                                    <p className="m-0 text-white small">{counter.heading}</p>
                                                </div>
                                            </div>
                                        )
                                    }
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="Sec4 grey">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 783.89" fill="var(--white)"><path d="M0,783.89S26.21,629,236,673.89c316.5,67.61,242.5-92,468-180.07,235.51-92,311.49,38,449.22-6.8C1251.61,455,1231,287.83,1412,303.83c67.48,6,188-80,188-80V0H0Z"></path></svg>
                    <div className="container">
                        {
                            blogloader ?
                                <BlogLoader />
                                :
                                <>
                                    {
                                        blogcms ?
                                            <div className="row mb-4 justify-content-center">
                                                <div className="col-lg-9 col-md-10 text-center">
                                                    <span className="SubTitle thm1">{blogcms.title}</span>
                                                    <h2 className="Heading">{blogcms.heading}</h2>
                                                    <div dangerouslySetInnerHTML={{ __html: blogcms.description }} />
                                                </div>
                                            </div>
                                            :
                                            ''
                                    }
                                    {
                                        blogs.length > 0 ?
                                            <>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="blogbox">
                                                            <OwlCarousel loop margin={10} className="owl-carousel blog" nav={false} dots={false} responsive={{ 0: { items: 1 }, 350: { items: 1 }, 460: { items: 2 }, 600: { items: 2 }, 768: { items: 2 }, 992: { items: 3 }, 1200: { items: 3 }, 1600: { items: 3 } }}>
                                                                {
                                                                    blogs.map((blog, b) =>
                                                                        <Link to={"/blog/" + blog.alias} key={b} className="card">
                                                                            <div className="card-header">
                                                                                {
                                                                                    helpers.helpers1(blog.image) ?
                                                                                        <picture>
                                                                                            <img src={process.env.REACT_APP_IMAGE_FOLDER + 'blog/' + blog.image} alt={blog.title} width="121" height="131" />
                                                                                        </picture>
                                                                                        :
                                                                                        <picture>
                                                                                            <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'blog/webp/' + blog.image + '.webp'} type="image/webp" />
                                                                                            <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'blog/avif/' + blog.image + '.avif'} type="image/webp" />
                                                                                            <img src={process.env.REACT_APP_IMAGE_FOLDER + 'blog/jpg/' + blog.image + '.jpg'} alt={blog.title} width="121" height="131" />
                                                                                        </picture>
                                                                                }
                                                                            </div>
                                                                            <div className="card-body px-0 text-center">
                                                                                <span className="badge text-black bgthm1">{moment(blog.post_date).format('DD MMMM YYYY')}</span>
                                                                                <h3 className="h5 mt-2">{blog.title}</h3>
                                                                            </div>
                                                                        </Link>
                                                                    )
                                                                }
                                                            </OwlCarousel>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-center"><Link to="/blogs" className="btn btn-thm">View all Blog</Link></div>
                                            </>
                                            : ''
                                    }
                                </>
                        }

                        <div className="pt-4 mt-5">
                            {
                                faqscms ?
                                    <div className="row justify-content-center">
                                        <div className="col-lg-9 col-md-10 text-center">
                                            <span className="SubTitle thm1">{faqscms.title}</span>
                                            <h2 className="Heading">{faqscms.heading}</h2>
                                            <div dangerouslySetInnerHTML={{ __html: faqscms.description }} />
                                        </div>
                                    </div>
                                    : ''
                            }

                            {
                                faqs.length > 0 ?

                                    <div className="row justify-content-center">
                                        <div className="col-lg-9 col-md-10">
                                            <div className="accordion accordion-flush Faqs" id="Faqs">
                                                {
                                                    faqs.map((faq, f) =>
                                                        <div className="accordion-item" key={f}>
                                                            <div className="accordion-header" id={"Pay1" + f}>
                                                                <button className={
                                                                    f === 0 ? "accordion-button" : "accordion-button collapsed"
                                                                } type="button" data-bs-toggle="collapse" data-bs-target={"#Faqs" + f} aria-expanded="true" aria-controls="Faqs1">{faq.title}</button>
                                                            </div>
                                                            <div id={"Faqs" + f} className={
                                                                f === 0 ? "accordion-collapse collapse show" : "accordion-collapse collapse"}
                                                                aria-labelledby="Pay1" data-bs-parent="#Faqs">
                                                                <div className="accordion-body" dangerouslySetInnerHTML={{ __html: faq.description }} />
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    ''
                            }
                        </div>
                    </div>
                </section>

                <section className="Sec5 Home">
                    <div className="container">
                        {
                            testimonialcms ?
                                <div className="row justify-content-center">
                                    <div className="col-lg-9 col-md-10 text-center">
                                        <span className="SubTitle thm1">{testimonialcms.title}</span>
                                        <h2 className="Heading">{testimonialcms.heading}</h2>
                                        <div dangerouslySetInnerHTML={{ __html: testimonialcms.description }} />
                                    </div>
                                </div>
                                :
                                ''
                        }

                        {
                            testimonials.length > 0 ?
                                <div className="row justify-content-center">
                                    <div className="col-lg-9 col-md-10">
                                        <div className="TestiBox">
                                            <OwlCarousel items={1} autoplay={true} className="testi">
                                                {
                                                    testimonials.map((testimonial, ts) =>
                                                        <div className="card card-body" key={ts}>
                                                            <div className="img">
                                                                <div dangerouslySetInnerHTML={{ __html: testimonial.description }} />
                                                            </div>
                                                            <div className="text">
                                                                {
                                                                    helpers.helpers1(testimonial.image) ?
                                                                        <picture>
                                                                            <img src={process.env.REACT_APP_IMAGE_FOLDER + 'testimonial/' + testimonial.image} alt={testimonial.title} width="120" height="50" />
                                                                        </picture>
                                                                        :
                                                                        <picture>
                                                                            <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'testimonial/webp/' + testimonial.image + '.webp'} type="image/webp" />
                                                                            <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'testimonial/avif/' + testimonial.image + '.avif'} type="image/webp" />
                                                                            <img src={process.env.REACT_APP_IMAGE_FOLDER + 'testimonial/jpg/' + testimonial.image + '.jpg'} alt={testimonial.title} width="120" height="50" />
                                                                        </picture>
                                                                }
                                                                <div className="ms-3">
                                                                    <h3 className="h5 m-0 fw-bold lh-n">{testimonial.title}</h3>
                                                                    {/* <h4 className="h6 m-0 lh-n">Company Name</h4> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </OwlCarousel>
                                        </div>
                                    </div>
                                </div>
                                :
                                ''
                        }
                    </div>
                </section>

                {
                    clients.length ?
                        <section className="ClientSec p-0">
                            <div className="row">
                                <div className="col-lg-2 col-md-3 p-0">
                                    <div className="bgthm text-center text-lg-end text-white text-u h3 h-100 d-grid align-items-center p-4 lh-n m-0 ps-lg-4">Our Clients</div>
                                </div>
                                <div className="col-lg-10 col-md-9 p-0 py-md-4">
                                    <div className='d-flex overflow-hidden'>
                                        <OwlCarousel items={6} autoplay={true} margin={8} loop={true} dots={false} nav={false} autoplaySpeed={2000} autoplayTimeout={2000} autoplayHoverPause={true} className="SliderBox" responsive={{ 0: { items: 3 }, 350: { items: 3 }, 460: { items: 4 }, 600: { items: 4 }, 768: { items: 5 }, 992: { items: 5 }, 1200: { items: 6 }, 1600: { items: 6 } }}>
                                            {
                                                clients.map((client, cl) =>
                                                    <div className='Client' key={cl}>
                                                        {
                                                            helpers.helpers1(client.image) ?
                                                                <picture>
                                                                    <img loading="lazy" src={process.env.REACT_APP_IMAGE_FOLDER + 'clients/' + client.image} alt={client.title} className="w-100" width="150" height="100" />
                                                                </picture>
                                                                :
                                                                <picture>
                                                                    <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'clients/webp/' + client.image + '.webp'} type="image/webp" />
                                                                    <img loading="lazy" src={process.env.REACT_APP_IMAGE_FOLDER + 'clients/jpg/' + client.image + '.jpg'} alt={client.title} className="w-100" width="150" height="100" />
                                                                </picture>
                                                        }
                                                    </div>
                                                )
                                            }
                                        </OwlCarousel>                                    
                                    </div>
                                </div>
                            </div>
                        </section>
                        :
                        ''
                }



                {
                    contactloader ?
                        <ContactLoader />
                        :
                        <section className="Sec6 Contact">
                            <div className="container">
                                {
                                    contactcms ?
                                        <div className="row justify-content-center">
                                            <div className="col-lg-9 col-md-10 text-center">
                                                <span className="SubTitle thm1">{contactcms.title}</span>
                                                <h2 className="Heading">{contactcms.heading}</h2>
                                                <div dangerouslySetInnerHTML={{ __html: contactcms.description }} />
                                            </div>
                                        </div>
                                        :
                                        ''
                                }

                                <div className="row">
                                    <div className="col-lg-4 order-lg-last">
                                        {
                                            contactcms ?
                                                helpers.helpers1(contactcms.image) ?
                                                    <picture>
                                                        <img src={process.env.REACT_APP_IMAGE_FOLDER + 'cms/' + contactcms.image} alt={contactcms.title} width="750" height="400" className="w-100 bgimgS" />
                                                    </picture>
                                                    :
                                                    <picture>
                                                        <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'cms/webp/' + contactcms.image + '.webp'} type="image/webp" />
                                                        <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'cms/avif/' + contactcms.image + '.avif'} type="image/webp" />
                                                        <img src={process.env.REACT_APP_IMAGE_FOLDER + 'cms/jpg/' + contactcms.image + '.jpg'} alt={contactcms.title} width="750" height="400" className="w-100 bgimgS" />
                                                    </picture>
                                                :
                                                <img src={require("../img/contact.svg").default} alt="contact" className="w-100" width="750" height="400" />

                                        }

                                        {
                                            contacts.length > 0 && contacts.map((list, ls) =>
                                                <div className="bgthm ConInfo mb-2" key={ls}>
                                                    <ul>
                                                        <li><img src={require("../img/location-w.svg").default} alt="location" /> <span><strong>{list.title}</strong><br />{list.address}</span></li>
                                                        {
                                                            list.phone ?
                                                                <li><img src={require("../img/call-w.svg").default} alt="call" /> <a href={'tel:' + list.phone}>{list.phone}</a></li>
                                                                :
                                                                ''
                                                        }
                                                        {
                                                            list.email ?
                                                                <li><img src={require("../img/mail-w.svg").default} alt="mail" /> <a href={'mailto:' + list.email}>{list.email}</a></li>
                                                                :
                                                                ''
                                                        }
                                                    </ul>
                                                </div>
                                            )
                                        }

                                    </div>
                                    <div className="col-lg-8">
                                        <form id="formsubmit" onSubmit={ContactForm}>
                                            <h3 className="h4 m-0">Get in Touch</h3><hr />
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="Name" className="form-label m-0">Your Name</label>
                                                    <input className="form-control" id="Name" value={name} onChange={(e) => setName(e.target.value)} type="text" />
                                                    {error.name ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{error.name}</span> : ''}
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="CName" className="form-label m-0">Your Company Name</label>
                                                    <input className="form-control" id="CName" value={companyname} onChange={(e) => setCompanyname(e.target.value)} type="text" />
                                                    {error.companyname ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{error.companyname}</span> : ''}
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="Email" className="form-label m-0">Your Email ID</label>
                                                    <input className="form-control" id="Email" value={email} onChange={(e) => setEmail(e.target.value)} type="email" />
                                                    {error.email ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{error.email}</span> : ''}
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="Contact" className="form-label m-0">Your Contact No.</label>
                                                    <input className="form-control" id="Contact" value={phone} onChange={(e) => setPhone(e.target.value)} type="number" maxLength="10" />
                                                    {error.phone ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{error.phone}</span> : ''}
                                                </div>
                                                <div className="col-12 mb-3">
                                                    <label htmlFor="Message" className="form-label m-0">Message</label>
                                                    <textarea className="form-control" id="Message" value={message} onChange={(e) => setMessage(e.target.value)} ></textarea>
                                                    {error.message ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{error.message}</span> : ''}
                                                </div>
                                            </div>
                                            {
                                                btnloader ?
                                                    <button type="button" className="btn btn-thm disabled"><FontAwesomeIcon icon={faSpinner} className="fa-spin me-1" /> Loading...</button>
                                                    :
                                                    <button type="submit" name="submit" className="btn btn-thm">Submit</button>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>
                }
                <section className="map p-0 lh-1"><iframe title='Location Map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.5188833390403!2d77.16810261272201!3d28.64417828241316!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d02ef1d6d6159%3A0x15037bd28ff5cf3c!2sSAM%20Web%20Studio!5e0!3m2!1sen!2sin!4v1666334014152!5m2!1sen!2sin" width="600" height="450" style={{ border: "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe></section>
            </main>
        </>
    )
}
