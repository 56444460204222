import axios from 'axios';
import React, { useEffect, useState } from 'react'
import helpers from '../../Helper';

export default function Corevalue() {
    const [corecms, setCorecms] = useState('');
    const [corevalues, setCorevalues] = useState([]);
    useEffect(() => {
        const fetchPage = async () => {
            await axios.get(process.env.REACT_APP_API + "getcmsdata/2")
                .then((response) => {
                    setCorecms(response.data.result);                    
                });
        }
        fetchPage();
        fetchCore();
    }, [setCorecms]);
    const fetchCore = async () => {
        await axios.get(process.env.REACT_APP_API + 'corevalues')
            .then((response) => {
                setCorevalues(response.data.result);
            })
    }
  return (
    <>
      <div className="col-12 mt-5 text-center">
                                {
                                    corecms ?
                                        <>
                                            <span className="SubTitle thm1">{corecms.title}</span>
                                            <h2 className="h2">{corecms.heading}</h2>
                                            <div dangerouslySetInnerHTML={{ __html: corecms.description }} />
                                        </>
                                        :
                                        ''
                                }

                                {
                                    corevalues.length > 0 ?
                                        <ul className="CValues m-0 p-0">
                                            {
                                                corevalues.map((corevalue, c) =>
                                                    <li key={c}>
                                                        <div>
                                                            {
                                                                helpers.helpers1(corevalue.image) ?
                                                                    <picture>
                                                                        <img src={process.env.REACT_APP_IMAGE_FOLDER + 'core/' + corevalue.image} alt={corevalue.title} width="80" height="80" />
                                                                    </picture>
                                                                    :
                                                                    <picture>
                                                                        <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'core/webp/' + corevalue.image + '.webp'} type="image/webp" />
                                                                        <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'core/avif/' + corevalue.image + '.avif'} type="image/webp" />
                                                                        <img src={process.env.REACT_APP_IMAGE_FOLDER + 'core/jpg/' + corevalue.image + '.jpg'} alt={corevalue.title} width="80" height="80" />
                                                                    </picture>
                                                            }
                                                            <h4 className="h6">{corevalue.title}</h4>
                                                        </div>
                                                    </li>
                                                )
                                            }


                                        </ul>
                                        :
                                        ''
                                }
                            </div>
    </>
  )
}
