import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'

export default function Header() {
    const [services, setServices] = useState([]);
    useEffect(() => {
        const fetchService = async () => {
            await axios.get(process.env.REACT_APP_API + 'services')
                .then((response) => {
                    setServices(response.data.result.data);
                })
        }
        fetchService();
    }, [setServices]);

    const [NavbarOpen, setNavbarOpen] = useState(false);
    return (
        <>
            <header></header>
            <nav className="navbar menu">
                <div className="st">
                    <div className="container">
                        <div className="row">
                            <div className="col logom">
                                <button aria-label="Navbar Button" className={NavbarOpen ? ' navbar-toggler MenuIcon d-lg-none' : 'navbar-toggler MenuIcon offcanvasClose d-lg-none'} onClick={() => setNavbarOpen((NavbarOpen) => !NavbarOpen)}><svg viewBox="0 0 30 26" fill="var(--black)"><path d="M9.42,19.56l-6.5-6.51L9.56,6.41C10.62,5.35,9.26,3.83,8.07,5l-8,8,8,8C9.08,22,10.52,20.66,9.42,19.56Z" /><path className="bar1" d="M1,0H29a1,1,0,0,1,0,2H1A1,1,0,0,1,1,0Z" /><path className="bar2" d="M1,24H29a1,1,0,0,1,0,2H1a1,1,0,1,1,0-2Z" /><path d="M10.93,12H29a1,1,0,0,1,0,2H10.93a1,1,0,0,1,0-2Z" /></svg></button>
                                <Link to="/" className="logo" title={process.env.REACT_APP_PROJECT}>
                                    <picture>
                                        <source srcSet={require("../../img/logo.webp")} type="image/webp" />
                                        <img loading="lazy" src={require("../../img/logo.jpg")} alt={process.env.REACT_APP_PROJECT} width="150" height="150" />
                                    </picture>
                                </Link>
                            </div>
                            <div className="col MenuBar">
                                <div className={NavbarOpen ? ' offcanvas offcanvas-start justify-content-between show' : 'offcanvas offcanvas-start justify-content-between'} id="navigatin" data-bs-scroll="false" data-bs-backdrop="false" tabIndex="-1">
                                    <ul className="navbar-nav">
                                        <li className="nav-item"><NavLink className="nav-link" title="Home" to="/" onClick={() => setNavbarOpen(false)}><span>Home</span></NavLink></li>
                                        <li className="nav-item"><NavLink className="nav-link" title="About Us" to="/about" onClick={() => setNavbarOpen(false)}><span>About Us</span></NavLink></li>
                                        <li className="nav-item dropdown"><NavLink title="Services" className="nav-link dropdown-toggle d-none d-lg-block" to="/services" onClick={() => setNavbarOpen(false)} role="button" aria-haspopup="true" aria-expanded="false"><span>Services</span></NavLink><a href='/#' title="Services" className="nav-link dropdown-toggle d-lg-none" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span>Services</span></a>
                                            <ul className="dropdown-menu" aria-labelledby="servicesDropdown">
                                                {
                                                    services.map((service, sr) =>
                                                        <li key={sr}><NavLink title={service.title} to={"/service/" + service.alias} onClick={() => setNavbarOpen(false)}>{service.title}</NavLink></li>
                                                    )
                                                }
                                            </ul>
                                        </li>
                                        <li className="nav-item"><NavLink className="nav-link" title="Clients" to="/clients" onClick={() => setNavbarOpen(false)}><span>Clients</span></NavLink></li>
                                        <li className="nav-item"><NavLink className="nav-link" title="Career" to="/careers" onClick={() => setNavbarOpen(false)}><span>Career</span></NavLink></li>
                                        <li className="nav-item"><NavLink className="nav-link" title="Gallery" to="/gallery" onClick={() => setNavbarOpen(false)}><span>Gallery</span></NavLink></li>
                                        <li className="nav-item"><NavLink className="nav-link" title="Gallery" to="/awards" onClick={() => setNavbarOpen(false)}><span>Awards</span></NavLink></li>
                                        <li className="nav-item"><NavLink className="nav-link" title="Blogs" to="/blogs" onClick={() => setNavbarOpen(false)}><span>Blogs</span></NavLink></li>
                                        <li className="nav-item"><NavLink className="nav-link" title="Contact Us" to="/contact" onClick={() => setNavbarOpen(false)}><span>Contact Us</span></NavLink></li>
                                    </ul>
                                </div>
                                <div className="LastH">
                                    <a href="tel:+919898989898" className="IconImg d-lg-none" title="Call Now"><img src={require("../../img/support1.svg").default} width="42" height="42" alt="support" /></a>
                                    <a href="mailto:contact@intelligence.view.org.in" className="IconImg d-lg-none" title="Email"><img src={require("../../img/mail.svg").default} width="42" height="42" alt="email" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}
