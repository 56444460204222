import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import moment from 'moment';
export default function Footer() {
    const [services, setServices] = useState([]);
    const [socialmedias, setSocialmedias] = useState([]);

    useEffect(() => {
        const fetchService = async () => {
            await axios.get(process.env.REACT_APP_API + 'services')
                .then((response) => {
                    setServices(response.data.result.data);
                })
        }
        fetchService();
    }, [setServices]);

    useEffect(() => {
        const fetchDate = async () => {
            await axios.get(process.env.REACT_APP_API + 'socialmedia')
            .then((response) => {
                setSocialmedias(response.data.result);                    
            })
        }
        fetchDate();
    }, [setSocialmedias]);


    return (
        <>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 text-center">
                            <Link to="/">
                                <picture>
                                    <source srcSet={require("../../img/logo.webp")} type="image/webp" />
                                    <img loading="lazy" src={require("../../img/logo.jpg")} alt={process.env.REACT_APP_PROJECT} className="flogo" width="120" height="120" />
                                </picture>
                            </Link>
                            <ul className="icons p-0 m-0 mt-4">
                                {
                                    socialmedias.map((socialmedia, sm) => 
                                    <li key={sm}>
                                        {
                                            socialmedia.title === 'facebook' ? 
                                                <a href={socialmedia.link} target="_blank" rel="noreferrer" title="Facebook">
                                                    <img loading="lazy" src={require("../../img/facebook-w.svg").default} alt="Facebook" width="24" height="24" />
                                                </a>
                                            :
                                            socialmedia.title === 'twitter' ? 
                                                <a href={socialmedia.link} target="_blank" rel="noreferrer" title="Twitter">
                                                    <img loading="lazy" src={require("../../img/twitter-w.svg").default} alt="Twitter" width="24" height="24" />
                                                </a>
                                            :
                                            socialmedia.title === 'linkedin' ? 
                                                <a href={socialmedia.link} target="_blank" rel="noreferrer" title="Linkedin">
                                                    <img loading="lazy" src={require("../../img/linkedin-w.svg").default} alt="Linkedin" width="24" height="24" />
                                                </a>
                                            :
                                            socialmedia.title === 'instagram' ? 
                                                <a href={socialmedia.link} target="_blank" rel="noreferrer" title="Instagram">
                                                    <img loading="lazy" src={require("../../img/instagram-w.svg").default} alt="Instagram" width="24" height="24" />
                                                </a>
                                            :
                                            socialmedia.title === 'youtube' ?
                                                <a href={socialmedia.link} target="_blank" rel="noreferrer" title="Youtube">
                                                    <img loading="lazy" src={require("../../img/youtube-w.svg").default} alt="Youtube" width="24" height="24" />
                                                </a>
                                            :
                                            socialmedia.title === 'pinterest'?
                                                <a href={socialmedia.link} target="_blank" rel="noreferrer" title="Pinterest">
                                                    <img loading="lazy" src={require("../../img/pinterest-w.svg").default} alt="Pinterest" width="24" height="24" />
                                                </a>
                                            :
                                            ''
                                        }
                                    </li>
                                    )
                                }
                                <li className="d-none d-lg-inline-block"><a href="https://api.whatsapp.com/send?phone=+919353075858&amp;text=Hi, I would like to get more information.." target="_blank" rel="noreferrer" title="Whatsapp"><img loading="lazy" src={require("../../img/whatsapp-w.svg").default} alt="Whatsapp" width="24" height="24" /></a></li>
                                <li className="d-lg-none"><a href="whatsapp://send?text=Hi, I would like to get more information..!&amp;phone=+919353075858" target="_blank" rel="noreferrer" title="Whatsapp"><img loading="lazy" src={require("../../img/whatsapp-w.svg").default} alt="Whatsapp" width="24" height="24" /></a></li>
                            </ul>
                        </div>
                        <div className="col-lg-2">
                            <h3 className="h6 fw-bold mb-3">General</h3>
                            <ul className="links">
                                <li><Link to="/about">About Us</Link></li>
                                <li><Link to="/careers">Careers</Link></li>
                                <li><Link to="/blogs">Blog</Link></li>
                                <li><Link to="/contact">Contact</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-4">
                            <h3 className="h6 fw-bold mb-3">Services</h3>
                            <ul className="links">
                                {
                                    services.map((service, sr) =>
                                        <li key={sr}><Link title={service.title} to={"/service/" + service.alias}>{service.title}</Link></li>
                                    )
                                }
                            </ul>
                        </div>
                        <div className="col-lg-3">
                            <h3 className="h6 fw-bold mb-3">Informations</h3>
                            <ul className="links">
                                <li><Link to="/faqs">FAQ</Link></li>
                                <li><Link to="/gallery">Gallery</Link></li>
                                <li><Link to="/awards">Awards</Link></li>
                                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                <li><Link to="/terms-and-conditions">Terms of Conditions</Link></li>
                            </ul>
                        </div>

                    </div>
                </div>
                <div className="fbottom mt-4">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4 text-start">
                                <p><small>&#169; {moment().format('YYYY')} <strong>{process.env.REACT_APP_PROJECT}</strong>. All Rights Reserved.</small></p>
                            </div>
                            <div className="col-lg-4">&nbsp;
                            </div>
                            <div className="col-lg-4 text-end">
                                <p><small>Made with &nbsp;<strong><span className="text-danger">&#10084;</span></strong>&nbsp; by <strong><a href="https://www.samwebstudio.com/" rel="noreferrer" target="_blank">SAM Web Studio</a></strong></small></p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <a href="/#" className="BackTop" style={{ display: 'none' }}><span><i></i><span>Back to top</span></span></a>
            <ToastContainer />
        </>
    )
}
