import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
export default function GetAQuotes() {
    const [btnloader, setBtnloader] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [error, setError] = useState({ name: "", email: "", phone: "" });

    const [qname, setQname] = useState("");
    const [qemail, setQemail] = useState("");
    const [qphone, setQphone] = useState("");
    const [qresponse, setQResponse] = useState("");
    const [qmessage, setQMessage] = useState("");
    const [qerror, setQerror] = useState({ name: "", email: "", phone: "", response: "", message: "" });


    async function FQuoteRequest(e) {
        e.preventDefault();
        setBtnloader(true);
        setError({ name: "", email: "", phone: "" });
        let callback = { name: name, email: email, phone: phone, }
        await axios.post(process.env.REACT_APP_API + 'callback', callback)
            .then((response) => {
                toast.success(response.data.message);
                setBtnloader(false);
                setName("");
                setEmail("");
                setPhone("");
            })
            .catch((errors) => {
                if (errors.response !== undefined) {
                    let rterror = errors.response.data.errors;
                    setError({
                        name: rterror.name,
                        email: rterror.email,
                        phone: rterror.phone
                    });
                    setBtnloader(false);
                }
            });
    }

    async function FQuoteRequest2(e) {
        e.preventDefault();
        setBtnloader(true);
        setQerror({ name: "", email: "", phone: "", response: "", message: "" });
        let callback = { name: qname, email: qemail, phone: qphone, response:qresponse,message:qmessage }
        await axios.post(process.env.REACT_APP_API + 'quoterequest', callback)
            .then((response) => {
                toast.success(response.data.message);
                setBtnloader(false);
                setQname("");
                setQemail("");
                setQphone("");
                setQResponse("");
                setQMessage("");
                setQerror({ name: "", email: "", phone: "", response: "", message: "" });
            })
            .catch((errors) => {
                if (errors.response !== undefined) {
                    let rterror = errors.response.data.errors;
                    setQerror({
                        name: rterror.name,
                        email: rterror.email,
                        phone: rterror.phone,
                        response: rterror.response,
                        message: rterror.message
                    });
                    setBtnloader(false);
                }
            });
    }
    return (
        <>
            <section className="GetAQuotes pt-4">
                <div className="container">
                    <div className="BuyBtnBox">
                        <div>
                            <h2 className="h4 mb-0"><strong>Interested in this Services?</strong></h2>
                            <p className="mb-0">Ask for more Details &amp; Latest Services Price</p>
                        </div>
                        <div>
                            <a href="#CallBack" data-bs-toggle="modal" className="btn btn-thm2 mt-0 ms-3" title="Request a Call Back">Request a Call Back</a>
                            <a href="#SendInquiry" data-bs-toggle="modal" className="btn btn-thm mt-0 ms-3" title="Send Inquiry">Get a Quotes</a>
                        </div>
                    </div>
                </div>
            </section>

            <div className="modal fade PopupM" id="SendInquiry" tabIndex="-1" aria-labelledby="SendInquiryLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <form className="modal-content" onSubmit={FQuoteRequest2}>
                        <div className="modal-header border-0 d-none">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body pt-4 px-md-4">
                            <h3 className="fw-bold h4 thm">Get a Quotes</h3>
                            <p>fill out the form below and resolve your problems.</p>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" value={qname} onChange={(e) => { setQname(e.target.value) }} name="name" placeholder="Name" />
                                        <label htmlFor="name" className="form-label">Name<span className="text-danger">*</span></label>
                                        {qerror.name ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{qerror.name}</span> : ''}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-floating mb-3">
                                        <input type="email" className="form-control" value={qemail} onChange={(e) => { setQemail(e.target.value) }} name="email" placeholder="Email Address" />
                                        <label htmlFor="email" className="form-label">Email Address<span className="text-danger">*</span></label>
                                        {qerror.email ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{qerror.email}</span> : ''}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-floating mb-3">
                                        <input type="number" className="form-control" value={qphone} onChange={(e) => { setQphone(e.target.value) }} name="mobile" placeholder="Contact Number" />
                                        <label htmlFor="mobile" className="form-label">Contact Number<span className="text-danger">*</span></label>
                                        {qerror.phone ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{qerror.phone}</span> : ''}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-floating mb-3">
                                        <select className="form-control form-select" value={qresponse} onChange={(e) => { setQResponse(e.target.value) }} id="response">
                                            <option value="email-id">Email ID</option>
                                            <option value="phone-call">Phone Call</option>
                                            <option value="text-message">Text Message</option>
                                        </select>
                                        <label htmlFor="response" className="form-label">How would you want to get a response<span className="text-danger">*</span></label>
                                        {qerror.response ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{qerror.response}</span> : ''}
                                    </div>

                                </div>
                                <div className="col-12">
                                    <div className="form-floating mb-3">
                                        <textarea className="form-control" id="problems" value={qmessage} onChange={(e) => { setQMessage(e.target.value) }} name="problems" placeholder="Your Problem"></textarea>
                                        <label htmlFor="mobile" className="form-label">Your Problem<span className="text-danger">*</span></label>
                                        {qerror.message ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{qerror.message}</span> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer pt-3 border-top pb-4 d-block text-center">
                            <button type="button" className="btn btn-thm3" data-bs-dismiss="modal">Close</button>
                            {
                                btnloader ?
                                    <button type="button" className="btn btn-thm disabled"><FontAwesomeIcon icon={faSpinner} className="fa-spin me-1" /> Loading...</button>
                                    :
                                    <button className="btn btn-thm">Submit Now</button>
                            }
                        </div>
                    </form>
                </div>
            </div>
            <div className="modal fade PopupM" id="CallBack" tabIndex="-1" aria-labelledby="CallBackLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <form action="#" className="modal-content" onSubmit={FQuoteRequest}>
                        <div className="modal-header border-0 d-none">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body pt-4 px-md-4">
                            <h3 className="fw-bold h4 thm">Request a Call Back</h3>
                            <p>Fill out the form below and Call Back <strong>Intelligence Security of India</strong> team.</p>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} name="name" placeholder="Name" />
                                        <label htmlFor="name" className="form-label">Name<span className="text-danger">*</span></label>
                                        {error.name ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{error.name}</span> : ''}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating mb-3">
                                        <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} name="email" placeholder="Email Address" />
                                        <label htmlFor="email" className="form-label">Email Address<span className="text-danger">*</span></label>
                                        {error.email ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{error.email}</span> : ''}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating mb-3">
                                        <input type="number" className="form-control" value={phone} onChange={(e) => setPhone(e.target.value)} name="mobile" placeholder="Contact Number" />
                                        <label htmlFor="mobile" className="form-label">Contact Number<span className="text-danger">*</span></label>
                                        {error.phone ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{error.phone}</span> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer pt-3 border-top pb-4 d-block text-center">
                            <button type="button" className="btn btn-thm3" data-bs-dismiss="modal">Close</button>
                            {
                                btnloader ?
                                    <button type="button" className="btn btn-thm disabled"><FontAwesomeIcon icon={faSpinner} className="fa-spin me-1" /> Loading...</button>
                                    :
                                    <button className="btn btn-thm">Submit Now</button>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
