import React from "react"
import ContentLoader from "react-content-loader"

const MyLoader = (props) => (
    <div className="pt-4 mb-5 HomeLOad">
        <div className='container text-center' style={{opacity:'.2'}}>
            <ContentLoader viewBox="0 0 700 550"  width={600} height={500} backgroundColor="#fff" foregroundColor="#999">
                <rect x="100" y="250" rx="30" ry="30" width="500" height="50" />
                <rect x="0" y="350" rx="10" ry="10" width="700" height="15" />
                <rect x="25" y="390" rx="10" ry="10" width="650" height="15" />
                <rect x="200" y="430" rx="10" ry="10" width="300" height="15" />
                <rect x="275" y="490" rx="10" ry="10" width="150" height="50" />
            </ContentLoader>
        </div>
    </div>
)

export default MyLoader