import React from 'react'
import { Link } from 'react-router-dom'

export default function Page404() {
    return (
        <main>
            <section className="InnerHead bg-black" style={{ padding: "62px 0" }}>
                <div className="container mt-0 mb-0">

                </div>
            </section>
            <section className="Sec4">
                <div className="container text-center">
                    
                    <img src="https://static.vecteezy.com/system/resources/thumbnails/006/549/647/small/404-landing-page-free-vector.jpg" alt="Page Not Found"/>
                    <h5 className='mt-5' style={{color:"#ff6c16"}}>PAGE NOT FOUND</h5>
                    <p className='mb-0 text-secondary'>This page you are looking for doesn`t exists or an other error occurred.</p>
                    <p className='text-secondary'><Link to="/"  style={{color:"#ff6c16"}}>Go Back To Home</Link>, or head over to website to choose a new direction.</p>
                    
                </div>
            </section>
        </main>
    )
}
