import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import '../assets/about.min.css';
import helpers from '../Helper';
import Corevalue from './layout/Corevalue';
import Pageloader from '../loader/About';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
export default function About() {
    const [pagedata, setPagedata] = useState('');
    const [missiondata, setMissiondata] = useState('');
    const [pageloader, setPageloader] = useState(false);
    const [journey, setJourney] = useState('');
    useEffect(() => {
        setPageloader(true);
        const fetchPage = async () => {
            await axios.get(process.env.REACT_APP_API + "getcmsdata/6")
                .then((response) => {
                    setPagedata(response.data.result);
                    setPageloader(false);
                    fetchMission();
                });
        }
        fetchPage();

        const fetchMission = async () => {
            await axios.get(process.env.REACT_APP_API + "getcmsdata/5")
                .then((response) => {
                    setMissiondata(response.data.result);
                    fetchJourney();
                });
        }
        
    }, [setPagedata]);

    

    const fetchJourney = async () => {
        await axios.get(process.env.REACT_APP_API + "journey")
            .then((response) => {
                setJourney(response.data.result);
            });
    }


    return (
        <>
            <Helmet>
                <title>About Us : Intelligence Security of India</title>
                <meta name="description" content="Welcome to Intelligence Security of India" />
                <meta name="keywords" content="Welcome to Intelligence Security of India" />
            </Helmet>
            <main>
                <section className="InnerHead bg-black">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 287.81"><path fill="#f36d22" d="M1600,41.81s-217-137-550,85C784.59,303.75,525,275.58,450,270.31c-72.82-5.12-197.95-53.5-312-53.5-42.61-.32-120.09,22.27-138,32,.06,0,0,35,0,35H1600Z" /><path fill="#fff" d="M1600,57.81s-267-99-540,66-470.52,158-575,158-281-51-397-51c-42.61,0-70.09,7.27-88,17,.06,0,0,40,0,40H1600Z" /></svg>
                    <picture>
                        <source srcSet={require("../img/banner1.webp")} type="image/webp" />
                        <img src={require("../img/banner1.jpg")} className="imgbg" alt="Gallery" width="1400" height="520" />
                    </picture>
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col-md-8">
                                <h1 className="fw-normal text-white">About us</h1>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/"><img src={require("../img/home.svg").default} width="14" height="14" alt="Home" /></Link></li>
                                    <li className="breadcrumb-item"><a href='/#' aria-current="page">About us</a></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="Sec1">
                    {
                        pageloader ?
                            <Pageloader />
                            :


                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-9 col-md-10 text-center mb-4">
                                        <span className="SubTitle thm1">{pagedata.title}</span>
                                        <h2 className="Heading">{pagedata.heading}</h2>
                                    </div>
                                    <div className="col-12 CmsPage">
                                        {
                                            helpers.helpers1(pagedata.image) ?
                                                <picture>
                                                    <img src={process.env.REACT_APP_IMAGE_FOLDER + 'cms/' + pagedata.image} alt={pagedata.title} width="300" height="453" className="h-auto float-md-end mb-md-5 ms-md-5 col-md-3" />
                                                </picture>
                                                :
                                                <picture>
                                                    <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'cms/webp/' + pagedata.image + '.webp'} type="image/webp" />
                                                    <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'cms/avif/' + pagedata.image + '.avif'} type="image/webp" />
                                                    <img src={process.env.REACT_APP_IMAGE_FOLDER + 'cms/jpg/' + pagedata.image + '.jpg'} alt={pagedata.title} width="300" height="453" className="h-auto float-md-end mb-md-5 ms-md-5 col-md-3" />
                                                </picture>
                                        }
                                        <div dangerouslySetInnerHTML={{ __html: pagedata.description }} />
                                    </div>
                                    <Corevalue />
                                </div>
                            </div>
                    }
                </section>

                {
                    missiondata ?
                        <section className="Sec2">
                            {
                                helpers.helpers1(missiondata.image) ?
                                    <picture>
                                        <img src={process.env.REACT_APP_IMAGE_FOLDER + 'cms/' + missiondata.image} alt={missiondata.title} width="750" height="400" className="bgimgS" />
                                    </picture>
                                    :
                                    <picture>
                                        <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'cms/webp/' + missiondata.image + '.webp'} type="image/webp" />
                                        <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'cms/avif/' + missiondata.image + '.avif'} type="image/webp" />
                                        <img src={process.env.REACT_APP_IMAGE_FOLDER + 'cms/jpg/' + missiondata.image + '.jpg'} alt={missiondata.title} width="750" height="400" className="bgimgS" />
                                    </picture>
                            }
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 offset-lg-6">
                                        <span className="SubTitle thm1">{missiondata.title}</span>
                                        <h2 className="Heading">{missiondata.heading}</h2>
                                        <div dangerouslySetInnerHTML={{ __html: missiondata.description }} />
                                    </div>
                                </div>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600.25 443.23"><path className="cls-1" d="M1226.25,22.23C906.62,133,798,241.73,729.25,305.23c-110.9,102.5-294.9,68.5-350,41s-227-81.5-312-62c-75,18.5-67,66-67,66v93h1600v-414S1381.58-31.59,1226.25,22.23Z" /></svg>
                        </section>
                        :
                        ''
                }

                {
                    journey.length > 0 ?
                        <section className="bgimg bg-black YearByYear">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-12">
                                        <OwlCarousel items={5} className="MyCarousel" loop autoplay={true} margin={0} nav={true} dots={false} navElement={'span'} navText={''} responsive={{ 0: { items: 1 }, 350: { items: 2 }, 460: { items: 2 }, 600: { items: 3 }, 768: { items: 3 }, 992: { items: 4 }, 1200: { items: 4 }, 1600: { items: 5 } }}>
                                            {
                                                journey.map((jour, j) =>
                                                <div className="YearBox CarBlock" key={j}>
                                                    <div className="Year">
                                                        <h2 className="m-0">{jour.year}</h2>
                                                    </div>
                                                    <div className="Msg">
                                                        <div className="MsgBox">{jour.title}</div>
                                                    </div>
                                                </div>
                                                )
                                            }
                                        </OwlCarousel>
                                        {/* <div className="PreNext"><span className="btn prev"></span><span className="btn next"></span></div> */}
                                    </div>
                                </div>
                            </div>
                        </section>
                        :
                        ''
                }




            </main>
        </>
    )
}
