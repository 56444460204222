import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useParams } from 'react-router-dom'
import helpers from '../Helper';
import Pageloader from '../loader/Cms1';

export default function Servicedetail() {
    const [services, setServices] = useState([]);
    const [otherservices, setOtherservices] = useState([]);
    const [pageloader, setPageloader] = useState(false);
    const param = useParams();
    
    useEffect(() => {
        setPageloader(true);
        const fetchService2 = async () => {
            await axios.get(process.env.REACT_APP_API + 'services?alias=' + param.alias)
                .then((response) => {
                    setOtherservices(response.data.result.data);
                })
        }
        const fetchService = async () => {
            await axios.get(process.env.REACT_APP_API + 'services/' + param.alias)
                .then((response) => {
                    setServices(response.data.result);
                    setPageloader(false);
                    fetchService2();
                })
        }
        fetchService();
    }, [param]);

    return (
        <>
            <Helmet>
                <title>{services ? services.meta_title : 'Services Detail : Intelligence Security of India'}</title>
                <meta name="description" content={services ? services.meta_description : 'Services Detail : Intelligence Security of India'} />
                <meta name="keywords" content={services ? services.meta_keywords : 'Services Detail : Intelligence Security of India'} />
            </Helmet>
            <main>
                <section className="InnerHead bg-black">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 287.81"><path fill="#f36d22" d="M1600,41.81s-217-137-550,85C784.59,303.75,525,275.58,450,270.31c-72.82-5.12-197.95-53.5-312-53.5-42.61-.32-120.09,22.27-138,32,.06,0,0,35,0,35H1600Z" /><path fill="#fff" d="M1600,57.81s-267-99-540,66-470.52,158-575,158-281-51-397-51c-42.61,0-70.09,7.27-88,17,.06,0,0,40,0,40H1600Z" /></svg>
                    <picture>
                        <source srcSet={require("../img/banner1.webp")} type="image/webp" />
                        <img src={require("../img/banner1.jpg")} className="imgbg" alt="Gallery" width="1400" height="520" />
                    </picture>
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col-md-8">
                                <h1 className="fw-normal text-white">{services ? services.title : 'Service Detail'}</h1>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/"><img src={require("../img/home.svg").default} width="14" height="14" alt="Home" /></Link></li>
                                    <li className="breadcrumb-item"><Link to="/services">Services</Link></li>
                                    <li className="breadcrumb-item"><a href='/#' aria-current="page">{services ? services.title : 'Service Detail'}</a></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    pageloader ?
                        <Pageloader />
                        :
                        <>
                            <section className=" Services">
                                <div className="container">
                                    {
                                        services ?
                                            <div className="row">
                                                <div className="col-12 CmsPage">
                                                    {
                                                        helpers.helpers1(services.image) ?
                                                            <picture>
                                                                <img src={process.env.REACT_APP_IMAGE_FOLDER + 'service/' + services.image} alt={services.title} className="h-auto float-md-end mb-md-5 ms-md-5 col-md-3" width="300" height="453" />
                                                            </picture>
                                                            :
                                                            <picture>
                                                                <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'service/webp/' + services.image + '.webp'} type="image/webp" />
                                                                <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'service/avif/' + services.image + '.avif'} type="image/webp" />
                                                                <img src={process.env.REACT_APP_IMAGE_FOLDER + 'service/jpg/' + services.image + '.jpg'} alt={services.title} className="h-auto float-md-end mb-md-5 ms-md-5 col-md-3" width="300" height="453" />
                                                            </picture>
                                                    }
                                                    <div dangerouslySetInnerHTML={{ __html: services.description }} />
                                                </div>
                                            </div>
                                            :
                                            ''
                                    }
                                </div>
                            </section>
                            <section className="Sec3 Services">
                                <div className="container">
                                    {
                                        otherservices.length > 0 ?
                                            <div className="row text-center justify-content-center">
                                                <div className="col-lg-9 col-md-10 text-center">
                                                    <span className="SubTitle thm1">Intelligence Security of India</span>
                                                    <h2 className="Heading">Other Services</h2>
                                                </div>
                                                {
                                                    otherservices.map((otherservice, o) =>
                                                        <div className="col-lg-4 col-md-6 mb-4" key={o}>
                                                            <Link to={"/service/"+otherservice.alias} className="card">
                                                            <div className="card-body">
                                                                    {
                                                                        helpers.helpers1(otherservice.image) ?
                                                                            <picture>
                                                                                <img src={process.env.REACT_APP_IMAGE_FOLDER + 'service/' + otherservice.image} alt={otherservice.title} width="80" height="80" />
                                                                            </picture>
                                                                            :
                                                                            <picture>
                                                                                <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'service/webp/' + otherservice.image + '.webp'} type="image/webp" />
                                                                                <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'service/avif/' + otherservice.image + '.avif'} type="image/webp" />
                                                                                <img src={process.env.REACT_APP_IMAGE_FOLDER + 'service/jpg/' + otherservice.image + '.jpg'} alt={otherservice.title} width="80" height="80" />
                                                                            </picture>
                                                                    }
                                                                    <h3 className="h5">{otherservice.title}</h3>
                                                                    <p dangerouslySetInnerHTML={{ __html: otherservice.short_description }} />
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    )
                                                }
                                                </div>
                                            :
                                            ''
                                    }
                                </div>
                            </section>
                        </>
                    }
            </main>
        </>
    )
}
