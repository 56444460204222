const helpers = {
    helpers1 : function checkimagetype(image){
        if (image) {
            let imageArr = image.split('.');
            imageArr = imageArr.slice(-1);
            let stringimg = imageArr.toString(); 
            if (stringimg === "webp" || stringimg === "svg" || stringimg === "avif") {          
                return true;
            } else {
            return false;
            }
        } else {
            return false;
        }
    }
}
export default helpers;