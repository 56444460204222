import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import './css/main-style.min.css';
import './css/style.min.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import Home from './component/Home';
import Header from './component/layout/Header';
import Footer from './component/layout/Footer';
import Page404 from './component/layout/Page404';
import Scrolltop from './component/layout/Scrolltop';
import About from './component/About';
import Gallery from './component/Gallery';
import Awards from './component/Awards';
import Career from './component/Career';
import Clients from './component/Clients';
import Blog from './component/Blog';
import Blogdetail from './component/Blogdetail';
import Blogcategory from './component/Blogcategory';
import Privacy from './component/Privacy';
import Terms from './component/Terms';
import Faqs from './component/Faqs';
import Contact from './component/Contact';
import Service from './component/Service';
import Servicedetail from './component/Servicedetail';
function App() {
  return (
    <>
      <HelmetProvider>
        <BrowserRouter basename='/'>
          <Scrolltop />
          <Header />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/gallery' element={<Gallery />} />
            <Route path='/awards' element={<Awards />} />
            <Route path='/careers' element={<Career />} />
            <Route path='/clients' element={<Clients />} />
            <Route path='/privacy-policy' element={<Privacy />} />
            <Route path='/terms-and-conditions' element={<Terms />} />
            <Route path='/faqs' element={<Faqs />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/services' element={<Service />} />
            <Route path='/service/:alias' element={<Servicedetail />} />
            <Route path='/blogs' element={<Blog />} />
            <Route path='/blog/:alias' element={<Blogdetail />} />
            <Route path='/blog-category/:alias' element={<Blogcategory />} />

            <Route path='/*' element={<Page404 />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </HelmetProvider>
    </>
  );
}

export default App;
