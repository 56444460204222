import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import helpers from '../Helper';
import Pageloader from '../loader/Service';
import '../assets/service.min.css';

export default function Service() {
    const [servicecms, setServicecms] = useState('');
    const [services, setServices] = useState([]);
    const [pageloader, setPageloader] = useState(false);


    useEffect(() => {
        setPageloader(true);
        const fetchService = async () => {
            await axios.get(process.env.REACT_APP_API + 'services')
                .then((response) => {
                    setServices(response.data.result.data);
                    setPageloader(false);
                })
        }
        fetchService();
    }, [setServices]);

    useEffect(() => {
        const fetchCms2 = async () => {
            await axios.get(process.env.REACT_APP_API + 'getcmsdata/14')
                .then((response) => {
                    setServicecms(response.data.result);
                })
        }
        fetchCms2();
    }, [setServicecms]);

    return (
        <>
            <Helmet>
                <title>Services : Intelligence Security of India</title>
                <meta name="description" content="Welcome to Intelligence Security of India" />
                <meta name="keywords" content="Welcome to Intelligence Security of India" />
            </Helmet>
            <main>
                <section className="InnerHead bg-black">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 287.81"><path fill="#f36d22" d="M1600,41.81s-217-137-550,85C784.59,303.75,525,275.58,450,270.31c-72.82-5.12-197.95-53.5-312-53.5-42.61-.32-120.09,22.27-138,32,.06,0,0,35,0,35H1600Z" /><path fill="#fff" d="M1600,57.81s-267-99-540,66-470.52,158-575,158-281-51-397-51c-42.61,0-70.09,7.27-88,17,.06,0,0,40,0,40H1600Z" /></svg>
                    <picture>
                        <source srcSet={require("../img/banner1.webp")} type="image/webp" />
                        <img src={require("../img/banner1.jpg")} className="imgbg" alt="Gallery" width="1400" height="520" />
                    </picture>
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col-md-8">
                                <h1 className="fw-normal text-white">Services</h1>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/"><img src={require("../img/home.svg").default} width="14" height="14" alt="Home" /></Link></li>
                                    <li className="breadcrumb-item"><a href='/#' aria-current="page">Services</a></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    pageloader ?
                        <Pageloader />
                        :
                        <section className="ServiceSec3 Services">
                            <div className="container">
                                {
                                    servicecms ?
                                        <div className="row mb-4 justify-content-center">
                                            <div className="col-lg-9 col-md-10 text-center">
                                                <span className="SubTitle thm1">{servicecms.title}</span>
                                                <h2 className="Heading">{servicecms.heading}</h2>
                                                <div dangerouslySetInnerHTML={{ __html: servicecms.description }} />
                                            </div>
                                        </div>
                                        :
                                        ''
                                }

                                {
                                    services.length > 0 ?
                                        <div className="row text-center justify-content-center">
                                            {
                                                services.map((service, sr) =>
                                                    <div className="col-lg-4 col-md-6 mb-4" key={sr}>
                                                        <Link to={"/service/" + service.alias} className="card">
                                                            <div className="card-body">
                                                                {
                                                                    helpers.helpers1(service.image) ?
                                                                        <picture>
                                                                            <img src={process.env.REACT_APP_IMAGE_FOLDER + 'service/' + service.image} alt={service.title} width="750" height="400" />
                                                                        </picture>
                                                                        :
                                                                        <picture>
                                                                            <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'service/webp/' + service.image + '.webp'} type="image/webp" />
                                                                            <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'service/avif/' + service.image + '.avif'} type="image/webp" />
                                                                            <img src={process.env.REACT_APP_IMAGE_FOLDER + 'service/jpg/' + service.image + '.jpg'} alt={service.title} width="750" height="400" />
                                                                        </picture>
                                                                }
                                                                <h3 className="h5">{service.title}</h3>
                                                                <p dangerouslySetInnerHTML={{ __html: service.short_description }} />
                                                            </div>
                                                        </Link>
                                                    </div>
                                                )
                                            }



                                        </div>
                                        :
                                        ''
                                }


                            </div>
                        </section>
                }
            </main>
        </>
    )
}
