import React from 'react'

export default function Dataempty() {
  return (
    <>
      <div className='text-center'>
      Data Not Available yet.
      </div>
    </>
  )
}
