import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useParams } from 'react-router-dom';
import '../assets/blogdtl.min.css';
import '../assets/blog.min.css';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import helpers from '../Helper';
import moment from 'moment';
import Pageloader from '../loader/Cms1';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    RedditIcon,
    RedditShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";


export default function Blogdetail() {
    const params = useParams();
    const [blogs, setBlogs] = useState([]);
    const [categories, setCategories] = useState([]);
    const [topblogs, setTopBlogs] = useState([]);
    const [relatedblogs, setRelatedBlogs] = useState([]);
    const [pageloader, setPageloader] = useState(false);

    const [btnloader, setBtnloader] = useState(false);
    const [email, setEmail] = useState("");
    const [emailerror, setEmailerror] = useState("");

    useEffect(() => {
        setPageloader(true);
        const fetchData = async () => {
            await axios.get(process.env.REACT_APP_API + 'blogs/' + params.alias).then((response) => {
                setBlogs(response.data.result);
                if (response.data.relateds !== undefined) { setRelatedBlogs(response.data.relateds.data); }
                setPageloader(false);
            });
        }
        fetchData();

        const fetchData2 = async () => {
            await axios.get(process.env.REACT_APP_API + 'blogs?type=topblogs&preblog=' + params.alias).then((response) => {
                if (response.data.result !== undefined) { setTopBlogs(response.data.result.data); }
            });
        }
        fetchData2();

    }, [params]);


    useEffect(() => {
        const fetchData3 = async () => {
            await axios.get(process.env.REACT_APP_API + 'blogcategory').then((response) => {
                setCategories(response.data.result);
            });
        }
        fetchData3();
    }, [setCategories]);

    async function SubscribeNewsletter(e) {
        e.preventDefault();
        setBtnloader(true);
        setEmailerror('');
        await axios.post(process.env.REACT_APP_API + 'newsletter', { email: email })
            .then((response) => {
                toast.success(response.data.message);
                setBtnloader(false);
                setEmail("");
            })
            .catch((errors) => {
                if (errors.response !== undefined) {
                    let rterror = errors.response.data.errors;
                    setEmailerror(rterror.email);
                    setBtnloader(false);
                }
            });
    }


    return (
        <>
            <Helmet>
                <title>{blogs.meta_title ? blogs.meta_title : 'Blog : Intelligence Security of India'}</title>
                <meta name="description" content={blogs.meta_description ? blogs.meta_description : 'Blog : Intelligence Security of India'} />
                <meta name="keywords" content={blogs.meta_keywords ? blogs.meta_keywords : 'Blog : Intelligence Security of India'} />
            </Helmet>
            <main>
                <section className="InnerHead bg-black">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 287.81"><path fill="#f36d22" d="M1600,41.81s-217-137-550,85C784.59,303.75,525,275.58,450,270.31c-72.82-5.12-197.95-53.5-312-53.5-42.61-.32-120.09,22.27-138,32,.06,0,0,35,0,35H1600Z" /><path fill="#fff" d="M1600,57.81s-267-99-540,66-470.52,158-575,158-281-51-397-51c-42.61,0-70.09,7.27-88,17,.06,0,0,40,0,40H1600Z" /></svg>
                    <picture>
                        <source srcSet={require("../img/banner1.webp")} type="image/webp" />
                        <img src={require("../img/banner1.jpg")} className="imgbg" alt="Gallery" width="1400" height="520" />
                    </picture>
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col-md-8">
                                <h1 className="fw-normal text-white">Blog</h1>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/"><img src={require("../img/home.svg").default} width="14" height="14" alt="Home" /></Link></li>
                                    <li className="breadcrumb-item"><Link to={"/blogs"}>Blog</Link></li>
                                    <li className="breadcrumb-item"><a href='/#' aria-current="page">Blog Details</a></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    pageloader ?
                        <Pageloader />
                        :
                        <section className="BlogDetail BlogCategory">
                            <div className="container">

                                <div className="row">
                                    <div className="col-lg-9 col-md-8 BlogList">
                                        <div className="DetailTop mb-4">
                                            {
                                                helpers.helpers1(blogs.banner) ?
                                                    <picture className="img">
                                                        <img loading="lazy" src={process.env.REACT_APP_IMAGE_FOLDER + 'blog/banner/' + blogs.banner} alt={blogs.title} width="121" height="131" />
                                                    </picture>
                                                    :
                                                    <picture className="img">
                                                        <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'blog/banner/webp/' + blogs.banner + '.webp'} type="image/webp" />
                                                        <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'blog/banner/avif/' + blogs.banner + '.avif'} type="image/avif" />
                                                        <img loading="lazy" src={process.env.REACT_APP_IMAGE_FOLDER + 'blog/banner/jpg/' + blogs.banner + '.jpg'} alt={blogs.title} width="121" height="131" />
                                                    </picture>
                                            }
                                            <h1 className="mt-4 h3">{blogs.title}</h1>
                                            <div className="d-flex mt-2"><a href="/#"><i className="fal fa-user-circle thm"></i> Admin</a> | <span><i className="far fa-calendar-alt thm"></i> {moment(blogs.post_date).format('DD MMMM YYYY')}</span></div>
                                        </div>
                                        <div className="CmsPage mt-3" dangerouslySetInnerHTML={{ __html: blogs.description }} />
                                        <div className="sharebox mt-4">
                                            <div className="row justify-content-between">
                                                <div className="col">
                                                    <strong>Share Now </strong>
                                                    <div id="social-links" className='ms-2'>
                                                        <ul>
                                                            <li><EmailShareButton url={process.env.REACT_APP_BASEURL + 'blog/' + params.alias}><EmailIcon size={32} round={true} /></EmailShareButton></li>
                                                            <li><FacebookShareButton url={process.env.REACT_APP_BASEURL + 'blog/' + params.alias}><FacebookIcon size={32} round={true} /> {shareCount => <span className="myShareCountWrapper">{shareCount}</span>}</FacebookShareButton></li>
                                                            <li><TwitterShareButton url={process.env.REACT_APP_BASEURL + 'blog/' + params.alias}><TwitterIcon size={32} round={true} /></TwitterShareButton></li>
                                                            <li><LinkedinShareButton url={process.env.REACT_APP_BASEURL + 'blog/' + params.alias}><LinkedinIcon size={32} round={true} /></LinkedinShareButton></li>
                                                            <li><TelegramShareButton url={process.env.REACT_APP_BASEURL + 'blog/' + params.alias}><TelegramIcon size={32} round={true} /></TelegramShareButton></li>
                                                            <li><WhatsappShareButton url={process.env.REACT_APP_BASEURL + 'blog/' + params.alias}><WhatsappIcon size={32} round={true} /></WhatsappShareButton></li>
                                                            <li><RedditShareButton url={process.env.REACT_APP_BASEURL + 'blog/' + params.alias}><RedditIcon size={32} round={true} /></RedditShareButton></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            relatedblogs.length > 0 ?

                                                <div className="Sec4 mt-4">
                                                    <h3 className="h4 thm">Related Articles</h3>
                                                    <OwlCarousel items={3} loop margin={8} className="blog" nav={false} dots={false} responsive={{ 0: { items: 1 }, 350: { items: 1 }, 460: { items: 2 }, 600: { items: 2 }, 768: { items: 2 }, 992: { items: 3 }, 1200: { items: 3 }, 1600: { items: 3 } }}>
                                                        {
                                                            relatedblogs.map((related, rl) =>
                                                                <Link to={"/blog/" + related.alias} key={rl} className="card">
                                                                    <div className="card-header">
                                                                        {
                                                                            helpers.helpers1(related.image) ?
                                                                                <picture className="img">
                                                                                    <img loading="lazy" src={process.env.REACT_APP_IMAGE_FOLDER + 'blog/' + related.image} alt={related.title} width="121" height="131" />
                                                                                </picture>
                                                                                :
                                                                                <picture className="img">
                                                                                    <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'blog/webp/' + related.image + '.webp'} type="image/webp" />
                                                                                    <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'blog/avif/' + related.image + '.avif'} type="image/avif" />
                                                                                    <img loading="lazy" src={process.env.REACT_APP_IMAGE_FOLDER + 'blog/jpg/' + related.image + '.jpg'} alt={related.title} width="121" height="131" />
                                                                                </picture>
                                                                        }
                                                                    </div>
                                                                    <div className="card-body px-0 text-center">
                                                                        <span className="badge text-black bgthm1">{moment(related.post_date).format('DD MMMM YYYY')}</span>
                                                                        <h3 className="h5 mt-2">{related.title}</h3>
                                                                    </div>
                                                                </Link>
                                                            )
                                                        }
                                                    </OwlCarousel>
                                                </div>

                                                : ''

                                        }
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-3 rightp">
                                        <div>
                                            {
                                                topblogs.length > 0 ?
                                                    <>
                                                        <h3>Most Popular Blogs</h3>
                                                        <div className="card MostBlogs">
                                                            <div className="card-body">
                                                                {
                                                                    topblogs.map((topblog, tb) =>
                                                                        <div key={tb}>
                                                                            <div className="img">
                                                                                {
                                                                                    helpers.helpers1(topblog.image) ?
                                                                                        <picture className="img">
                                                                                            <img loading="lazy" src={process.env.REACT_APP_IMAGE_FOLDER + 'blog/' + topblog.image} alt={topblog.title} width="121" height="131" />
                                                                                        </picture>
                                                                                        :
                                                                                        <picture className="img">
                                                                                            <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'blog/webp/' + topblog.image + '.webp'} type="image/webp" />
                                                                                            <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'blog/avif/' + topblog.image + '.avif'} type="image/avif" />
                                                                                            <img loading="lazy" src={process.env.REACT_APP_IMAGE_FOLDER + 'blog/jpg/' + topblog.image + '.jpg'} alt={topblog.title} width="121" height="131" />
                                                                                        </picture>
                                                                                }
                                                                            </div>
                                                                            <div className="text">
                                                                                <h3><Link to={"/blog/" + topblog.alias}>{topblog.title}</Link></h3>
                                                                                <div className="d-flex"><a href="/#"><i className="fal fa-user-circle"></i> Admin</a> | <span><i className="far fa-calendar-alt"></i> {moment(topblog.post_date).format('DD MMMM YYYY')}</span></div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }

                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    ''
                                            }
                                            <h3>Newsletter</h3>
                                            <div className="card Newsletter">
                                                <form onSubmit={SubscribeNewsletter} className="card-body">
                                                    <div className="form-group">
                                                        <label htmlFor="name" className="form-label">Email Address*</label>
                                                        <input type="text" className="form-control" id="name" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email " />
                                                        {emailerror ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{emailerror}</span> : ''}
                                                    </div>
                                                    <div className="text-center">
                                                        {
                                                            btnloader ?
                                                                <button type="button" className="btn btn-thm btn-sm mt-3 disabled"><FontAwesomeIcon icon={faSpinner} className="fa-spin me-1" /> Loading...</button>
                                                                :
                                                                <button type="submit" className="btn btn-thm btn-sm mt-3"><span>Subscribe</span></button>
                                                        }
                                                    </div>
                                                </form>
                                            </div>

                                            {
                                                categories.length > 0 ?

                                                    <>
                                                        <h3>Categories</h3>
                                                        <div className="card MostBlogs">
                                                            <div className="card-body Archives">
                                                                <ul className="list-group list-group-flush">
                                                                    {
                                                                        categories.map((category, c) =>
                                                                            <li className="list-group-item" key={c}>
                                                                                <Link to={"/blog-category/" + category.alias}>
                                                                                    <i className="fal fa-arrow-right me-2"></i> {category.title}
                                                                                </Link>
                                                                            </li>
                                                                        )
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    ''
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                }
            </main>
        </>
    )
}
