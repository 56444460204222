import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import '../assets/gallery.min.css';
import helpers from '../Helper';
import { Fancybox } from '@fancyapps/ui/dist/fancybox/fancybox.esm.js';
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import Pageloader from '../loader/Gallery';

export default function Gallery() {
    Fancybox.bind('[data-fancybox="gallery"]', {});
    const [pagedata, setPagedata] = useState([]);
    const [galleries, setGallery] = useState([]);
    const [pageloader, setPageloader] = useState(false);
    useEffect(() => {
        setPageloader(true);
        const fetchPage = async () => {
            await axios.get(process.env.REACT_APP_API + "getcmsdata/20")
                .then((response) => {
                    setPagedata(response.data.result);
                    fetachData();
                    setPageloader(false);
                });
        }
        fetchPage();
    }, [setPagedata]);

    const fetachData = async () => {
        await axios.get(process.env.REACT_APP_API + "aboutgallery")
            .then((response) => {
                setGallery(response.data.result.data);
            });
    }


    return (
        <>
            <Helmet>
                <title>Gallery : Intelligence Security of India</title>
                <meta name="description" content="Welcome to Intelligence Security of India" />
                <meta name="keywords" content="Welcome to Intelligence Security of India" />
            </Helmet>
            <main>
                <section className="InnerHead bg-black">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 287.81"><path fill="#f36d22" d="M1600,41.81s-217-137-550,85C784.59,303.75,525,275.58,450,270.31c-72.82-5.12-197.95-53.5-312-53.5-42.61-.32-120.09,22.27-138,32,.06,0,0,35,0,35H1600Z" /><path fill="#fff" d="M1600,57.81s-267-99-540,66-470.52,158-575,158-281-51-397-51c-42.61,0-70.09,7.27-88,17,.06,0,0,40,0,40H1600Z" /></svg>
                    <picture>
                        <source srcSet={require("../img/banner1.webp")} type="image/webp" />
                        <img src={require("../img/banner1.jpg")} className="imgbg" alt="Gallery" width="1400" height="520" />
                    </picture>
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col-md-8">
                                <h1 className="fw-normal text-white">Gallery</h1>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/"><img src={require("../img/home.svg").default} width="14" height="14" alt="Home" /></Link></li>
                                    <li className="breadcrumb-item"><a href='/#' aria-current="page">Gallery</a></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                    {
                        pageloader ?
                            <Pageloader />
                            :
                <section>
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-10 col-md-11 text-center">
                                        <span className="SubTitle thm1">{pagedata.title}</span>
                                        <h2 className="Heading">{pagedata.heading}</h2>
                                        <div dangerouslySetInnerHTML={{ __html: pagedata.description }} />
                                    </div>
                                </div>
                                <div className="row">
                                    {
                                        galleries.map((gallery, gr) =>
                                            <div className="col-lg-3 col-md-4 col-6 mb-4" key={gr}>
                                                {
                                                    helpers.helpers1(gallery.image) ?
                                                        <a href={process.env.REACT_APP_IMAGE_FOLDER + 'about/' + gallery.image} className="gallery card" data-fancybox="gallery">
                                                            <div className="card-body p-0">
                                                                <picture>
                                                                    <img src={process.env.REACT_APP_IMAGE_FOLDER + 'about/' + gallery.image} alt={"Gallery"} width="162" height="162" />
                                                                </picture>
                                                            </div>
                                                        </a>
                                                        :
                                                        <a href={process.env.REACT_APP_IMAGE_FOLDER + 'about/jpg/' + gallery.image + '.jpg'} className="gallery card" data-fancybox="gallery">
                                                            <div className="card-body p-0">
                                                                <picture>
                                                                    <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'about/webp/' + gallery.image + '.webp'} type="image/webp" />
                                                                    <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'about/avif/' + gallery.image + '.avif'} type="image/webp" />
                                                                    <img src={process.env.REACT_APP_IMAGE_FOLDER + 'about/jpg/' + gallery.image + '.jpg'} alt={"Gallery"} width="162" height="162" />
                                                                </picture>
                                                            </div>
                                                        </a>
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                </section>
                    }
            </main>
        </>
    )
}
