import axios from 'axios';
import React, { useEffect, useState } from 'react'
import moment from 'moment';
import Pageloader from '../loader/Blog';
import { Link, useParams } from 'react-router-dom';
import helpers from '../Helper';
import { Helmet } from 'react-helmet-async';
import Dataempty from './layout/Dataempty';

export default function Blogcategory() {
    const params = useParams();
    const [blogs, setBlogs] = useState([]);
    const [pageloader, setPageloader] = useState(false);
    const [pagedata, setPagedata] = useState();

    useEffect(() => {
        setPageloader(true);
        async function fetchData() {
            await axios.get(process.env.REACT_APP_API + 'blogcategory?category=' + params.alias)
                .then((response) => {
                    setPagedata(response.data.result);
                    setPageloader(false);

                });
        }
        fetchData();
    },[]);

    useEffect(() => {
        const fetchData2 = async () => {
            await axios.get(process.env.REACT_APP_API + 'blogs?category=' + params.alias).then((response) => {
                setBlogs(response.data.result.data);
            });
        }
        fetchData2();
    },[]);

    return (
        <>
            <Helmet>
                <title>{blogs ? blogs.meta_title : 'This data is not available yet.'}</title>
            </Helmet>
            <main>
                <section className="InnerHead bg-black">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 287.81"><path fill="#f36d22" d="M1600,41.81s-217-137-550,85C784.59,303.75,525,275.58,450,270.31c-72.82-5.12-197.95-53.5-312-53.5-42.61-.32-120.09,22.27-138,32,.06,0,0,35,0,35H1600Z" /><path fill="#fff" d="M1600,57.81s-267-99-540,66-470.52,158-575,158-281-51-397-51c-42.61,0-70.09,7.27-88,17,.06,0,0,40,0,40H1600Z" /></svg>
                    <picture>
                        <source srcSet={require("../img/banner1.webp")} type="image/webp" />
                        <img src={require("../img/banner1.jpg")} className="imgbg" alt="Gallery" width="1400" height="520" />
                    </picture>
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col-md-8">
                                <h1 className="fw-normal text-white">{pagedata ? pagedata.title : 'Blog Category'}</h1>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/"><img src={require("../img/home.svg").default} width="14" height="14" alt="Home" /></Link></li>
                                    <li className="breadcrumb-item"><Link to="/blogs">Blogs</Link></li>
                                    <li className="breadcrumb-item"><a href='/#' aria-current="page">{pagedata ? pagedata.title : 'Blog Category'}</a></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    pageloader ?
                        <Pageloader />
                        :
                        <section className="Sec4">

                            <div className="container">
                                {
                                    blogs.length > 0 ?
                                        <div className="row blogbox">
                                            {
                                                blogs.map((blog, b) =>

                                                    <div key={b} className="col-lg-4 col-sm-6 blog mb-4">
                                                        <Link to={"/blog/" + blog.alias} className="card">
                                                            <div className="card-header">
                                                                {
                                                                    helpers.helpers1(blog.image) ?
                                                                        <picture className="img">
                                                                            <img loading="lazy" src={process.env.REACT_APP_IMAGE_FOLDER + 'blog/' + blog.image} alt={blog.title} width="121" height="131" />
                                                                        </picture>
                                                                        :
                                                                        <picture className="img">
                                                                            <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'blog/webp/' + blog.image + '.webp'} type="image/webp" />
                                                                            <source srcSet={process.env.REACT_APP_IMAGE_FOLDER + 'blog/avif/' + blog.image + '.avif'} type="image/avif" />
                                                                            <img loading="lazy" src={process.env.REACT_APP_IMAGE_FOLDER + 'blog/jpg/' + blog.image + '.jpg'} alt={blog.title} width="121" height="131" />
                                                                        </picture>
                                                                }
                                                            </div>
                                                            <div className="card-body px-0 text-center">
                                                                <span className="badge text-black bgthm1">{moment(blog.post_date).format('DD MMMM YYYY')}</span>
                                                                <h3 className="h5 mt-2">{blog.title}</h3>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                )
                                            }


                                        </div>
                                        :
                                        <Dataempty />
                                }
                            </div>
                        </section>
                }
            </main>
        </>
    )
}